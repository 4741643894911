import React from 'react'
import Spinner from './Spinner'

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    containerClass?: string
    loading?: boolean
    variant?: 'solid' | 'bordered'
}

const Button = ({ containerClass, loading, variant, ...props }: Props) => {
    return <div className={containerClass ? containerClass : ''}>
        {loading
            ? <button {...props} className={`mt-button text-truncate ${variant === 'bordered' ? 'mt-button-bordered' : ''} ${props.className ? props.className : ''}`} onClick={e => e.preventDefault()}>
                <Spinner />
            </button>
            : <button {...props} className={`mt-button text-truncate ${variant === 'bordered' ? 'mt-button-bordered' : ''} ${props.className ? props.className : ''}`} />}
    </div>
}

export default Button