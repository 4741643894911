import { Dispatch } from 'redux'
import { api, errorHandler, Response } from '../../config'
import { GetState } from '../reducers'

export enum TripActionsList {
    ADD_TRIP = 'ADD_TRIP',
    UPDATE_TRIP = 'UPDATE_TRIP',
    DELETE_TRIP = 'DELETE_TRIP',
    FETCH_ALL_TRIPS = 'FETCH_ALL_TRIPS',
}

export interface Trip {
    id?: string
    from: string
    to: string
    distance: number
    distanceUnit?: string
    project: string
    comments?: string
    amount?: number
    date: string
}

export interface AddTripAction {
    type: TripActionsList.ADD_TRIP
    data: Trip
}

export interface UpdateTripAction {
    type: TripActionsList.UPDATE_TRIP
    data: Trip
}

export interface FetchAllTripsAction {
    type: TripActionsList.FETCH_ALL_TRIPS
    data: Trip[]
}

export interface DeleteTripAction {
    type: TripActionsList.DELETE_TRIP
    data: string
}

export type TripActions = AddTripAction | UpdateTripAction | DeleteTripAction | FetchAllTripsAction

export const addTrip = (data: Trip) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.post<Response<Trip>>('trip', data, config).then(response => {
        if (response.status === 200) {
            dispatch<AddTripAction>({
                type: TripActionsList.ADD_TRIP,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Trip added')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to add trip',
            showConnectionError: true
        }))
    })
}

export const updateTrip = (data: Trip, tripId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.put<Response<Trip>>(`trip/${tripId}`, data, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateTripAction>({
                type: TripActionsList.UPDATE_TRIP,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Trip updated')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to update',
            showConnectionError: true
        }))
    })
}

export const deleteTrip = (tripId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.delete<Response<Trip>>(`trip/${tripId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<DeleteTripAction>({
                type: TripActionsList.DELETE_TRIP,
                data: tripId
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Trip deleted')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to delete',
            showConnectionError: true
        }))
    })
}

export const fetchAllTrips = () => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.get<Response<Trip[]>>('trip', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchAllTripsAction>({
                type: TripActionsList.FETCH_ALL_TRIPS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : '')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to fetch',
            logout: true,
            showConnectionError: true
        }))
    })
}
