import { Reducer } from 'redux'
import { Trip, TripActionsList } from '../actions/trip.actions'
import { Action } from '../actions/types'

const initialValue: Trip[] = []

const tripReducer: Reducer<Trip[], Action> = (state: Trip[] = initialValue, action: Action): Trip[] => {
    switch (action.type) {
        case TripActionsList.ADD_TRIP: {
            return [
                action.data,
                ...state
            ]
        }

        case TripActionsList.UPDATE_TRIP: {
            const updateTripArray = [...state]
            const updateTripIndex = updateTripArray.findIndex(uta => uta.id === action.data.id)

            if (updateTripIndex > -1) {
                updateTripArray[updateTripIndex] = action.data
            }

            return updateTripArray
        }

        case TripActionsList.DELETE_TRIP: {
            return state.filter(s => s.id !== action.data)
        }

        case TripActionsList.FETCH_ALL_TRIPS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default tripReducer
