import { Dispatch } from 'redux'

export enum AxiosRequestConfigActionsList {
    ADD_AXIOS_REQUEST_CONFIG = 'ADD_AXIOS_REQUEST_CONFIG',
    REMOVE_AXIOS_REQUEST_CONFIG = 'REMOVE_AXIOS_REQUEST_CONFIG'
}

export interface AddAxiosRequestConfigAction {
    type: AxiosRequestConfigActionsList.ADD_AXIOS_REQUEST_CONFIG
    data: string
}

export interface RemoveAxiosRequestConfigAction {
    type: AxiosRequestConfigActionsList.REMOVE_AXIOS_REQUEST_CONFIG
    data: null
}

export type AxiosRequestConfigActions = AddAxiosRequestConfigAction | RemoveAxiosRequestConfigAction

export const addAxiosRequestConfig = (token: string) => async (dispatch: Dispatch) => {
    dispatch<AddAxiosRequestConfigAction>({
        type: AxiosRequestConfigActionsList.ADD_AXIOS_REQUEST_CONFIG,
        data: token
    })
}

export const removeAxiosRequestConfig = () => async (dispatch: Dispatch) => {
    dispatch<RemoveAxiosRequestConfigAction>({
        type: AxiosRequestConfigActionsList.REMOVE_AXIOS_REQUEST_CONFIG,
        data: null
    })
}