import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AuthendicationPage from '../components/AuthendicationPage'
import Button from '../components/Button'
import FormInput from '../components/FormInput'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { login } from '../redux/actions/authUser.actions'
import { AppDispatch } from '../redux/store'
import { ActivateAccountNavigationState } from './ActivateAccount'
import { InvitaitonParams } from './Invitation'

const Login = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { token } = useParams<InvitaitonParams>()

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')

    const [emailError, setEmailError] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')

    const [loginLoading, setLoginLoading] = React.useState<boolean>(false)

    const resetErrorStates = () => {
        setEmailError('')
        setPasswordError('')
    }

    const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    const continueClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        resetErrorStates()

        let error = false
        const loginData = {
            email,
            password
        }

        if (email === '') {
            setEmailError(t('Email required'))
            error = true
        }

        if (password === '') {
            setPasswordError(t('Password required'))
            error = true
        }

        if (!error) {
            setLoginLoading(true)
            dispatch(login(loginData)).then(text => {
                if (token) {
                    navigate(`/invitation/${token}`)
                }
                toast(t(text))
            }).catch(error => {
                toast(t(error), ToastTypes.ERROR)
                if (error === 'Account is not active') {
                    navigate('/activate_account', {
                        state: {
                            email: email,
                            isLogin: true,
                            password: password
                        } as ActivateAccountNavigationState
                    })
                }
            }).finally(() => {
                setLoginLoading(false)
            })
        }
    }

    return <AuthendicationPage headerText={t('Login')}>
        <form>
            <FormInput
                label={t('Email')}
                type='email'
                placeholder={t('Email')}
                containerClass='mb-24px'
                errorText={emailError}
                onChange={emailChangeHandler}
                value={email}
            />

            <FormInput
                label={t('Password')}
                type='password'
                placeholder={t('Password')}
                containerClass='mb-16px'
                errorText={passwordError}
                onChange={passwordChangeHandler}
                value={password}
            />

            <Link to='/forgot_password' className='link'>{t('Forgot password')}?</Link>

            <Button
                containerClass='mt-48px mb-44px'
                onClick={continueClickHandler}
                loading={loginLoading}
            >{t('Continue')}</Button>

        </form>

        <div className='fs-14px fw-regular text-prussian-blue-2 text-center mb-8px'>{t('Don\'t have account')}?</div>

        <Link to='/sign_up' className='mt-button'>{t('Sign up')}</Link>
    </AuthendicationPage>
}

export default Login