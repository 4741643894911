import { Reducer } from 'redux'
import { Language, LanguageActionsList } from '../actions/language.actions'
import { Action } from '../actions/types'

const initialValue: Language = 'en'

const languageReducer: Reducer<Language, Action> = (state: Language = initialValue, action: Action): Language => {
    switch (action.type) {
        case LanguageActionsList.CHANGE_LANGUAGE: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default languageReducer
