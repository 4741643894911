import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { monthNames } from '../config'
import { fetchMonthlyAnalytics, MonthlyAnalytics } from '../redux/actions/monthlyAnalytics.actions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import Analytics from './Analytics'

interface GraphData {
    monthName: string
    monthNumber: number
    distance: number
    amount: number
}

const Monthly = () => {
    const dispatch = useDispatch<AppDispatch>()

    const monthlyAnalytics = useSelector<StoreState, MonthlyAnalytics[]>(state => state.monthlyAnalytics)

    const [distanceData, setDistanceData] = React.useState<number[]>([])
    const [savingsData, setSavingsData] = React.useState<number[]>([])
    const [labels, setLabels] = React.useState<string[]>([])

    const totalDistance = React.useMemo(() => {
        return distanceData.reduce((prev, cur) => prev + cur, 0)
    }, [distanceData])

    const totalSavings = React.useMemo(() => {
        return savingsData.reduce((prev, cur) => prev + cur, 0)
    }, [savingsData])

    React.useEffect(() => {
        const month = new Date().getMonth() + 1
        const months = monthNames.map((mn, i) => ({ monthName: mn, monthNumber: i + 1 }))
        const afterMonths = months.slice(month)
        const beforeMonths = months.slice(0, month)

        const rearrangedMonths = [...afterMonths, ...beforeMonths]

        const data = rearrangedMonths.map<GraphData>(rm => {
            const index = monthlyAnalytics.findIndex(ma => {
                const yearMonth = ma.year_month.split('-')
                return rm.monthNumber === Number(yearMonth[1])
            })

            return {
                ...rm,
                amount: index > -1 ? monthlyAnalytics[index].amount : 0,
                distance: index > -1 ? monthlyAnalytics[index].distance : 0
            }
        })

        setDistanceData(data.map(d => d.distance))
        setSavingsData(data.map(d => d.amount))
        setLabels(data.map(d => d.monthName))

    }, [monthlyAnalytics])

    React.useEffect(() => {
        dispatch(fetchMonthlyAnalytics())
    }, [])

    return <Analytics
        distanceData={distanceData}
        labels={labels}
        savingsData={savingsData}
        totalDistance={totalDistance}
        totalSavings={totalSavings}
        savingsLabel='Monthly savings'
        distanceLabel='Monthly traveled'
    />
}

export default Monthly