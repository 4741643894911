import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import Modal from './Modal'

interface Props {
    visible?: boolean
    headerText?: string
    onYes?: React.MouseEventHandler<HTMLButtonElement>
    onNo?: React.MouseEventHandler<HTMLButtonElement>
    loading?: boolean
}

const ConfirmationModal = (props: Props) => {
    const { t } = useTranslation()

    return <Modal visible={props.visible}>
        <div className='w-300px px-30px'>
            <div className='fs-16px fw-bold text-uppercase text-003651 mb-16px text-center'>{props.headerText}</div>
            <div className='hstack gap-3'>
                <Button onClick={props.onYes} containerClass='w-100' loading={props.loading}>{t('Yes')}</Button>
                <Button onClick={props.onNo} containerClass='w-100'>{t('No')}</Button>
            </div>
        </div>
    </Modal>
}

export default ConfirmationModal