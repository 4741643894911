import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { formatDate, formatDateInputValue } from '../config'
import { fetchDailyAnalytics } from '../redux/actions/dailyAnalytics.actions'
import { fetchMonthlyAnalytics } from '../redux/actions/monthlyAnalytics.actions'
import { addTrip, deleteTrip, Trip, updateTrip } from '../redux/actions/trip.actions'
import { fetchYearlyAnalytics } from '../redux/actions/yearlyAnalytics.actions'
import { AppDispatch } from '../redux/store'
import Button from './Button'
import ConfirmationModal from './ConfirmationModal'
import DateInput from './DateInput'
import FormInput from './FormInput'
import { ToastTypes, useToast } from './toast/ToastProvider'

interface Props {
    onCancel?: () => void
    formType?: 'ADD' | 'EDIT'
    tripData?: Trip
}

const TripForm = (props: Props) => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const { t } = useTranslation()
    const dateInputElement = React.useRef<ReactDatePicker<never, undefined>>(null)

    const [distance, setDistance] = React.useState<string>('')
    const [date, setDate] = React.useState<string>('')
    const [dateFormatted, setDateFormatted] = React.useState<string>('')
    const [from, setFrom] = React.useState<string>('')
    const [to, setTo] = React.useState<string>('')
    const [purpose, setPurpose] = React.useState<string>('')
    const [comments, setComments] = React.useState<string>('')

    const [distanceError, setDistanceError] = React.useState<string>('')
    const [dateError, setDateError] = React.useState<string>('')
    const [fromError, setFromError] = React.useState<string>('')
    const [toError, setToError] = React.useState<string>('')
    const [purposeError, setPurposeError] = React.useState<string>('')
    const [commentsError, setCommentsError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false)
    const [showCalendar, setShowCalendar] = React.useState<boolean>(false)

    const distanceChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value
        setDistance(value)
        if (value !== '') {
            if (isNaN(Number(value))) {
                setDistanceError(t('Invalid distance value'))
            } else if (Number(value) <= 0) {
                setDistanceError(t('Invalid distance value'))
            } else if (Number(value) > 9999) {
                setDistanceError(t('Distance cannot be larger than 9999'))
            } else {
                setDistanceError('')
            }
        } else {
            setDistanceError('')
        }
    }

    const dateChangeHandler = (date: string, dateFormatted: string) => {
        setDate(date)
        setDateFormatted(dateFormatted)
        setDateError('')
        setShowCalendar(false)
    }

    const fromChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFrom(e.target.value)
        setFromError('')
    }

    const toChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTo(e.target.value)
        setToError('')
    }

    const purposeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPurpose(e.target.value)
        setPurposeError('')
    }

    const commentsChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setComments(e.target.value)
        setCommentsError('')
    }

    const dateFocusHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
        setShowCalendar(true)
        e.target.scrollIntoView({
            behavior: 'smooth'
        })
    }

    const dateBlurHandler: React.FocusEventHandler<HTMLInputElement> = () => {
        setShowCalendar(false)
    }

    const dateClickHandler: React.MouseEventHandler<SVGSVGElement> = () => {
        if (dateInputElement.current !== null) {
            dateInputElement.current.setFocus()
        }
    }

    const dateClickOutsideHandler: React.MouseEventHandler<HTMLDivElement> = () => {
        setShowCalendar(false)
    }

    const deleteClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        setShowDeleteModal(true)
    }

    const deleteNoClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        setShowDeleteModal(false)
    }

    const deleteYesClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        if (props.formType === 'EDIT' && props.tripData !== undefined && props.tripData.id !== undefined) {
            setDeleteLoading(true)
            dispatch(deleteTrip(props.tripData.id)).then(text => {
                toast(text)
                setShowDeleteModal(false)
                dispatch(fetchMonthlyAnalytics())
                dispatch(fetchDailyAnalytics())
                dispatch(fetchYearlyAnalytics())
                if (props.onCancel !== undefined) {
                    props.onCancel()
                }
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            }).finally(() => {
                setDeleteLoading(false)
            })
        }
    }

    const addTripClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        const data: Trip = {
            from: from,
            to: to,
            project: purpose,
            comments: comments,
            date: dateFormatted,
            distance: Number(Number(distance).toFixed(2))
        }

        let error = false

        if (distance === '') {
            setDistanceError(t('Distance required'))
            error = true
        } else if (isNaN(Number(distance))) {
            setDistanceError(t('Invalid distance value'))
            error = true
        } else if (Number(distance) <= 0) {
            setDistanceError(t('Invalid distance value'))
            error = true
        } else if (Number(distance) > 9999) {
            setDistanceError(t('Distance cannot be larger than 9999'))
            error = true
        }

        if (dateFormatted === '') {
            setDateError(t('Date required'))
            error = true
        }

        if (from === '') {
            setFromError(t('From required'))
            error = true
        }

        if (to === '') {
            setToError(t('To required'))
            error = true
        }

        if (purpose === '') {
            setPurposeError(t('Purpose required'))
            error = true
        }

        if (!error) {
            setLoading(true)
            if (props.formType === 'ADD') {
                dispatch(addTrip(data)).then(text => {
                    toast(t(text))
                    dispatch(fetchMonthlyAnalytics())
                    dispatch(fetchDailyAnalytics())
                    dispatch(fetchYearlyAnalytics())
                    if (props.onCancel !== undefined) {
                        props.onCancel()
                    }
                }).catch(error => {
                    toast(t(error), ToastTypes.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else if (props.formType === 'EDIT' && props.tripData !== undefined && props.tripData.id !== undefined) {
                dispatch(updateTrip(data, props.tripData.id)).then(text => {
                    toast(t(text))
                    dispatch(fetchMonthlyAnalytics())
                    dispatch(fetchDailyAnalytics())
                    dispatch(fetchYearlyAnalytics())
                    if (props.onCancel !== undefined) {
                        props.onCancel()
                    }
                }).catch(error => {
                    toast(t(error), ToastTypes.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    React.useEffect(() => {
        if (props.formType === 'EDIT' && props.tripData !== undefined && props.tripData.id !== undefined) {
            setDistance(props.tripData.distance.toString())
            setDate(formatDateInputValue(props.tripData.date))
            setDateFormatted(formatDate(props.tripData.date))
            setFrom(props.tripData.from)
            setTo(props.tripData.to)
            setPurpose(props.tripData.project)
            setComments(props.tripData.comments ? props.tripData.comments : '')
        }
    }, [props.formType, props.tripData])

    return <React.Fragment>
        <ConfirmationModal
            headerText={`${t('Are you sure want to delete')}?`}
            visible={showDeleteModal}
            onNo={deleteNoClickHandler}
            onYes={deleteYesClickHandler}
            loading={deleteLoading}
        />
        <form>
            <div className='fs-20px fw-bold text-uppercase text-003651 mb-16px text-center'>
                {t(`${props.formType === 'ADD' ? t('Add new') : props.formType === 'EDIT' ? 'Edit' : ''} trip`)}
            </div>
            <div className='fs-14px text-003651 mb-12px text-center'>{t('Enter distance travelled in Kilometers')}</div>

            {/* <DistanceDashboard /> */}

            <div className='mt-trip-modal-scroll-container'>

                <FormInput
                    type='text'
                    label={t('Distance')}
                    placeholder={t('Enter distance')}
                    containerClass='mb-20px'
                    value={distance}
                    errorText={distanceError}
                    onChange={distanceChangeHandler}
                    maxLength={4}
                />

                <DateInput
                    label={t('Date')}
                    maxDate={new Date()}
                    placeholder={t('Enter date')}
                    containerClass='mb-20px'
                    value={date}
                    errorText={dateError}
                    onChange={dateChangeHandler}
                    onFocus={dateFocusHandler}
                    onBlur={dateBlurHandler}
                    open={showCalendar}
                    onClick={dateClickHandler}
                    onClickOutside={dateClickOutsideHandler}
                    innerRef={dateInputElement}
                />

                <FormInput
                    type='text'
                    label={t('From')}
                    placeholder={t('Enter starting point')}
                    containerClass='mb-20px'
                    value={from}
                    errorText={fromError}
                    onChange={fromChangeHandler}
                />

                <FormInput
                    type='text'
                    label={t('To')}
                    placeholder={t('Enter destination point')}
                    value={to}
                    errorText={toError}
                    onChange={toChangeHandler}
                />

                <div className='mt-24px mb-20px h-1px bg-EFEFEF' />

                <FormInput
                    type='text'
                    label={t('Purpose')}
                    placeholder={t('Enter purpose')}
                    containerClass='mb-20px'
                    value={purpose}
                    errorText={purposeError}
                    onChange={purposeChangeHandler}
                />

                <FormInput
                    type='text'
                    label={t('Comments')}
                    placeholder={t('Enter comments')}
                    containerClass='mb-32px'
                    value={comments}
                    errorText={commentsError}
                    onChange={commentsChangeHandler}
                />

            </div>

            <div className='mt-24px px-22px'>
                <div className='row'>
                    <div className={`${props.formType === 'ADD' ? 'col-sm-6' : 'col-6 col-sm-4'}`}>
                        <Button variant='bordered' containerClass='w-100' onClick={props.onCancel} type='button'>{t('Cancel')}</Button>
                    </div>
                    {props.formType === 'EDIT' && <div className='col-6 col-sm-4'>
                        <Button variant='solid' containerClass='w-100' onClick={deleteClickHandler} type='button'>{t('Delete')}</Button>
                    </div>}
                    <div className='d-sm-none h-20px' />
                    <div className={`${props.formType === 'ADD' ? 'col-sm-6' : 'col-6 col-sm-4'}`}>
                        <Button
                            type='submit'
                            containerClass='w-100'
                            onClick={addTripClickHandler}
                            loading={loading}
                        >
                            {t(`${props.formType === 'ADD' ? 'Add' : props.formType === 'EDIT' ? 'Update' : ''} trip`)}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    </React.Fragment>
}

export default TripForm