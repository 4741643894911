import React from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { getDay, nth } from '../config'
import Label from './Label'
import ChevronDown from './svg/ChevronDown'

interface Props {
    value?: string
    placeholder?: string
    onChange?: (date: string, dateFormatted: string) => void
    onFocus?: React.FocusEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    onClick?: React.MouseEventHandler<SVGSVGElement>
    maxDate?: Date
    minDate?: Date
    containerClass?: string
    errorText?: string
    label?: string
    open?: boolean
    onClickOutside?: React.MouseEventHandler<HTMLDivElement>
    innerRef?: React.LegacyRef<ReactDatePicker<never, undefined>>
}

const DateInput = (props: Props) => {
    const isErrorExists = props.errorText !== undefined && props.errorText !== ''

    const dateChangeHandler = (date: Date) => {
        if (props.onChange !== undefined) {
            const dateObj = new Date(date)
            const day = dateObj.getDay()
            const dat = dateObj.getDate()
            const month = dateObj.toLocaleDateString('en-US', { month: 'short' })

            const dd = String(dat).padStart(2, '0')
            const mm = String(dateObj.getMonth() + 1).padStart(2, '0')
            const year = dateObj.getFullYear()

            props.onChange(`${getDay(day)}, ${month} ${dat}${nth(dat)}, ${year}`, `${year}-${mm}-${dd}`)
        }
    }

    return <div className={`position-relative w-100 ${props.containerClass ? props.containerClass : ''}`}>
        {props.label && props.label !== '' && <Label className='ps-25px mb-2px w-100 d-inline-block'>{props.label}</Label>}
        <div className='position-relative w-100'>
            <ReactDatePicker
                {...props}
                onChange={dateChangeHandler}
                placeholderText={props.placeholder}
                className={`${isErrorExists ? 'border-mandy' : ''}`}
                customInput={<input className='mt-input' />}
                ref={props.innerRef}
            />
            <ChevronDown className='position-absolute top-50 end-0 translate-middle me-10px cursor-pointer' onClick={props.onClick} />
        </div>
        {isErrorExists && <label className='fs-12px ps-24px w-100 d-inline-block text-mandy fw-semi-bold position-absolute top-100 start-0 fade-in lh-14px'>{props.errorText}</label>}
    </div>

}

export default DateInput