import { AxiosRequestConfig } from 'axios'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom'
import ActivateAccount from '../pages/ActivateAccount'
import CreateNewPassword from '../pages/CreateNewPassword'
import ForgotPassword from '../pages/ForgotPassword'
import Home from '../pages/Home'
import Invitation, { InvitaitonParams } from '../pages/Invitation'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import Teams from '../pages/Teams'
import { AuthUser } from '../redux/actions/authUser.actions'
import { Language } from '../redux/actions/language.actions'
import { updateNetworkError } from '../redux/actions/networkError.actions'
import { fetchAllTrips } from '../redux/actions/trip.actions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import Button from './Button'
import Modal from './Modal'

const Router = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { i18n, t } = useTranslation()

    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const axiosRequestConfig = useSelector<StoreState, AxiosRequestConfig | null>(state => state.axiosRequestConfig)
    const language = useSelector<StoreState, Language>(state => state.language)
    const networkError = useSelector<StoreState, boolean>(state => state.networkError)

    const authendicated = authUser !== null && authUser['auth-token'] !== '' && axiosRequestConfig !== null

    const okClickHandler = () => {
        dispatch(updateNetworkError(false))
    }

    React.useEffect(() => {
        if (authendicated) {
            dispatch(fetchAllTrips())
        }
    }, [authendicated])

    React.useEffect(() => {
        i18n.changeLanguage(language)
    }, [i18n, language])

    return <React.Fragment>
        <Modal visible={networkError}>
            <div className='w-300px px-30px'>
                <div className='fs-16px fw-bold text-uppercase text-003651 mb-16px text-center'>{`${t('No network connection')}!`}</div>
                <Button onClick={okClickHandler}>{t('Ok')}</Button>
            </div>
        </Modal>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={authendicated ? <Home /> : <Navigate to='/login' />} />
                <Route path='/teams' element={authendicated ? <Teams /> : <Navigate to='/login' />} />
                <Route path='/invitation/:token' element={<InvitaitonElement authendicated={authendicated} />} />
                <Route path='/login' element={!authendicated ? <Login /> : <Navigate to='/' />} />
                <Route path='/login/:token' element={!authendicated ? <Login /> : <Navigate to='/' />} />
                <Route path='/sign_up' element={!authendicated ? <Signup /> : <Navigate to='/' />} />
                <Route path='/activate_account' element={!authendicated ? <ActivateAccount /> : <Navigate to='/' />} />
                <Route path='/forgot_password' element={!authendicated ? <ForgotPassword /> : <Navigate to='/' />} />
                <Route path='/create_new_password' element={!authendicated ? <CreateNewPassword /> : <Navigate to='/' />} />
            </Routes>
        </BrowserRouter>
    </React.Fragment>
}

interface InvitaitonElementProps {
    authendicated: boolean
}

const InvitaitonElement = (props: InvitaitonElementProps) => {
    const { token } = useParams<InvitaitonParams>()
    return props.authendicated ? <Invitation /> : <Navigate to={token ? `/login/${token}` : '/login'} />
}

export default Router