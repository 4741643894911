import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from '../redux/store'
import Router from './Router'
import ToastProvider from './toast/ToastProvider'

const App = () => {
    return <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ToastProvider>
                <Router />
            </ToastProvider>
        </PersistGate>
    </Provider>
}

export default App
