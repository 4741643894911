import React from 'react'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Input = (props: Props) => {
    return <input
        {...props}
        className={`mt-input ${props.className ? props.className : ''}`}
    />
}

export default Input