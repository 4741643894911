import { Dispatch } from 'redux'
import { api, errorHandler, Response } from '../../config'
import { GetState } from '../reducers'

export enum DailyAnalyticsActionsList {
    FETCH_DAILY_ANALYTICS = 'FETCH_DAILY_ANALYTICS',
}

export interface DailyAnalytics {
    amount: number
    count: number
    distance: number
    month_date: string
}

export interface DailyAnalyticsAction {
    type: DailyAnalyticsActionsList.FETCH_DAILY_ANALYTICS
    data: DailyAnalytics[]
}

export type DailyAnalyticsActions = DailyAnalyticsAction

export const fetchDailyAnalytics = () => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.get<Response<DailyAnalytics[]>>('trip/analytics/daily', config).then(response => {
        if (response.status === 200) {
            dispatch<DailyAnalyticsAction>({
                type: DailyAnalyticsActionsList.FETCH_DAILY_ANALYTICS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message ? response.data.message : '')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to fetch',
            logout: true,
            showConnectionError: true
        }))
    })
}
