import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> {
    innerRef?: React.LegacyRef<SVGSVGElement>
}

const Globe = ({ innerRef, ...props }: Props) => {
    return <svg {...props} ref={innerRef} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className={`feather feather-globe ${props.className ? props.className : ''}`}>
        <circle cx='12' cy='12' r='10' />
        <line x1='2' y1='12' x2='22' y2='12' />
        <path d='M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z' />
    </svg>
}

export default Globe