import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from './Container'
import Daily from './Daily'
import Monthly from './Monthly'
import Quarterly from './Quarterly'
import Yearly from './Yearly'

type AnalyticsSection = 'Quarterly' | 'Daily' | 'Monthly' | 'Yearly'

const AnalyticsContainer = () => {
    const { t } = useTranslation()
    const [analyticsSection, setAnalyticsSection] = React.useState<AnalyticsSection>('Daily')

    const analyticsNavTabs: AnalyticsSection[] = [/* 'Quarterly', */ 'Daily', 'Monthly', 'Yearly']

    return <Container>
        <div className='px-22px'>
            <div className='fs-20px fw-bold text-uppercase text-003651 mb-32px'>Analytics</div>
            <div className='d-flex align-items-start justify-content-around h-30px border-bottom border-EFEFEF mb-35px'>
                {analyticsNavTabs.map(section => {
                    const analyticsNavTabClickHandler: React.MouseEventHandler<HTMLDivElement> = () => {
                        setAnalyticsSection(section)
                    }

                    return <AnalyticsNavTab key={section} active={analyticsSection === section} onClick={analyticsNavTabClickHandler}>{t(section)}</AnalyticsNavTab>
                })}
            </div>
        </div>
        <div className='mt-analytics-scroll-container'>
            {analyticsSection === 'Daily' && <Daily />}
            {analyticsSection === 'Monthly' && <Monthly />}
            {analyticsSection === 'Quarterly' && <Quarterly />}
            {analyticsSection === 'Yearly' && <Yearly />}
        </div>
    </Container>
}

interface AnalyticsNavProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean
}

const AnalyticsNavTab = ({ active, ...props }: AnalyticsNavProps) => {
    return <React.Fragment>
        <div {...props} className={`${active ? 'text-003651 mt-analytics-tab-active' : 'text-888888'} fw-bold fs-16px cursor-pointer d-inline-block ${props.className ? props.className : ''}`} />
    </React.Fragment>
}

export default AnalyticsContainer