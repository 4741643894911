import { Dispatch } from 'redux'
import { api, errorHandler, Response } from '../../config'
import { GetState } from '../reducers'
import {
	AddAxiosRequestConfigAction,
	AxiosRequestConfigActionsList,
	RemoveAxiosRequestConfigAction
} from './axiosRequestConfig.actions'

export enum AuthUserActionsList {
	LOGIN_USER = 'LOGIN_USER',
	UPDATE_USER = 'UPDATE_USER',
	SIGNUP_USER = 'SIGNUP_USER',
	LOGOUT_USER = 'LOGOUT_USER'
}

export interface AuthUser {
	userId: string
	'auth-token': string
	country: string
	currency: string
	status: string
	email: string
	measurementType: string
	mileageRate: number
	name: string
}

interface UpdateUserResponse {
	country: string
	email: string
	id: string
	name: string
	status: string
}

export interface LoginUser {
	email: string
	password: string
}

export interface LoginAction {
	type: AuthUserActionsList.LOGIN_USER
	data: AuthUser
}

export interface UpdateUserAction {
	type: AuthUserActionsList.UPDATE_USER
	data: AuthUser
}

export interface SignupAction {
	type: AuthUserActionsList.SIGNUP_USER
	data: null
}

export interface LogoutAction {
	type: AuthUserActionsList.LOGOUT_USER
	data: null
}

export type AuthUserActions = LoginAction | LogoutAction | UpdateUserAction

export const login = (data: LoginUser) => async (dispatch: Dispatch) => {
	return api
		.post<Response<AuthUser>>('user/login', data)
		.then((response) => {
			if (response.status === 200) {
				dispatch<LoginAction>({
					type: AuthUserActionsList.LOGIN_USER,
					data: response.data.data
				})

				dispatch<AddAxiosRequestConfigAction>({
					type: AxiosRequestConfigActionsList.ADD_AXIOS_REQUEST_CONFIG,
					data: response.data.data['auth-token']
				})

				return Promise.resolve<string>(response.data.message ? response.data.message : 'Logged in')
			} else {
				throw { response }
			}
		})
		.catch((error) => {
			return Promise.reject<string>(
				errorHandler(error, {
					errorText: 'Unable to login',
					showConnectionError: true
				})
			)
		})
}

export type SignupUser = LoginUser

export const signup = (data: SignupUser) => async (dispatch: Dispatch) => {
	return api
		.post<Response<AuthUser>>('user', data)
		.then((response) => {
			if (response.status === 200) {
				dispatch<SignupAction>({
					type: AuthUserActionsList.SIGNUP_USER,
					data: null
				})

				return Promise.resolve<string>(response.data.message ? response.data.message : 'Account created')
			} else {
				throw { response }
			}
		})
		.catch((error) => {
			return Promise.reject<string>(
				errorHandler(error, {
					errorText: 'Unable to signup',
					showConnectionError: true
				})
			)
		})
}

export const logout = () => async (dispatch: Dispatch) => {
	dispatch<LogoutAction>({
		type: AuthUserActionsList.LOGOUT_USER,
		data: null
	})

	dispatch<RemoveAxiosRequestConfigAction>({
		type: AxiosRequestConfigActionsList.REMOVE_AXIOS_REQUEST_CONFIG,
		data: null
	})
}

interface UpdateUser {
	name: string
	country: string
}

export const updateUser = (updateData: UpdateUser) => async (dispatch: Dispatch, getState: GetState) => {
	const config = getState().axiosRequestConfig!
	const user = getState().authUser!

	return api
		.put<Response<UpdateUserResponse>>('user', updateData, config)
		.then((response) => {
			if (response.status === 200) {
				const { name, country } = response.data.data
				dispatch<UpdateUserAction>({
					type: AuthUserActionsList.UPDATE_USER,
					data: {
						...user,
						name,
						country
					}
				})
				return Promise.resolve<string>(response.data.message ? response.data.message : 'Details updated')
			} else {
				throw { response }
			}
		})
		.catch((error) => {
			return Promise.reject<string>(
				errorHandler(error, {
					errorText: 'Unable to update',
					showConnectionError: true
				})
			)
		})
}
