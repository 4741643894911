import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthendicationPage from '../components/AuthendicationPage'
import Button from '../components/Button'
import FormInput from '../components/FormInput'
import OverlayLoader from '../components/OverlayLoader'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { api, errorHandler, Response } from '../config'
import { login } from '../redux/actions/authUser.actions'
import { AppDispatch } from '../redux/store'

export interface ActivateAccountNavigationState {
    email: string
    password: string
    isLogin: boolean
}

const ActivateAccount = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const navigate = useNavigate()
    const navigationState = useLocation().state as ActivateAccountNavigationState
    const { t } = useTranslation()

    const [userId, setUserId] = React.useState<string>('')
    const [otp, setOtp] = React.useState<string>('')
    const [otpError, setOtpError] = React.useState<string>('')

    const [activateAccountLoading, setActivateAccountLoading] = React.useState<boolean>(false)
    const [sendOtpLoading, setSendOtpLoading] = React.useState<boolean>(false)
    const [getUserIdLoading, setGetUserIdLoading] = React.useState<boolean>(true)

    const otpChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOtp(e.target.value)
        setOtpError('')
    }

    const sendOtp = () => {
        setSendOtpLoading(true)
        api.post('user/sendOTP', { email: navigationState.email }).then(response => {
            if (response.status === 200) {
                toast(response.data.message ? t(response.data.message) : t('OTP sent'), ToastTypes.SUCCESS)
            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }
        }).catch(error => {
            const text = errorHandler(error, {
                errorText: 'Unable to sent OTP',
                showConnectionError: true
            })

            toast(t(text), ToastTypes.ERROR)
        }).finally(() => {
            setSendOtpLoading(false)
        })
    }

    const resendOtpClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        sendOtp()
    }

    const continueClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (otp === '') {
            setOtpError(t('OTP required'))
            error = true
        }

        if (!error) {
            const data = { otp }

            setActivateAccountLoading(true)
            api.put<Response>(`/user/activate/${userId}`, data).then(response => {
                if (response.status === 200) {
                    toast(response.data.message ? t(response.data.message) : t('Account activated'), ToastTypes.SUCCESS)
                    dispatch(login({
                        email: navigationState.email,
                        password: navigationState.password
                    }))
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }
            }).catch(error => {
                const text = errorHandler(error, {
                    errorText: 'Unable to activate account',
                    showConnectionError: true
                })

                toast(t(text), ToastTypes.ERROR)
            }).finally(() => {
                setActivateAccountLoading(false)
            })
        }
    }

    React.useEffect(() => {
        if (!navigationState.email || !navigationState.password) {
            navigate(navigationState.isLogin ? '/login' : '/sign_up')
        } else if (navigationState.email && navigationState.password) {
            api.get(`user/user/${navigationState.email}`).then(response => {
                if (response.status === 200) {
                    setUserId(response.data.data.id)
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }
            }).catch(() => {
                navigate(navigationState.isLogin ? '/login' : '/sign_up')
            }).finally(() => {
                setGetUserIdLoading(false)
            })
        }
    }, [])

    React.useEffect(() => {
        if (navigationState.isLogin) {
            sendOtp()
        }
    }, [])

    return <React.Fragment>
        <OverlayLoader show={getUserIdLoading || sendOtpLoading} />
        <AuthendicationPage headerText={t('Activate Account')}>
            <form>

                <FormInput
                    label='OTP'
                    type='number'
                    placeholder='OTP'
                    containerClass='mb-24px'
                    errorText={otpError}
                    onChange={otpChangeHandler}
                    value={otp}
                />

                <Button
                    containerClass='mb-44px'
                    onClick={continueClickHandler}
                    loading={activateAccountLoading}
                >{t('Continue')}</Button>

            </form>

            <div className='fs-14px fw-regular text-prussian-blue-2 text-center mb-8px'>{t('Having trouble getting OTP')}?</div>

            <Button
                containerClass='mb-44px'
                onClick={resendOtpClickHandler}
            >{t('Resend OTP')}</Button>
        </AuthendicationPage>
    </React.Fragment>
}

export default ActivateAccount