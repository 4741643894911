import { Reducer } from 'redux'
import { TeamMember, TeamMembersActionsList } from '../actions/teamMembers.actions'
import { Action } from '../actions/types'

const initialValue: TeamMember[] = []

const teamMembersReducer: Reducer<TeamMember[], Action> = (state: TeamMember[] = initialValue, action: Action): TeamMember[] => {
    switch (action.type) {
        case TeamMembersActionsList.INVITE_TEAM_MEMBER: {
            return [
                ...state,
                action.data
            ]
        }

        case TeamMembersActionsList.CHANGE_TEAM_MEMBER_ROLE: {
            const index = state.findIndex(s => s.id === action.data.id)

            if (index > -1) {
                const newState = [...state]
                newState[index] = action.data
                return newState
            }

            return state
        }

        case TeamMembersActionsList.CHANGE_TEAM_MEMBER_STATUS: {
            const index = state.findIndex(s => s.id === action.data.id)

            if (index > -1) {
                const newState = [...state]
                newState[index] = action.data
                return newState
            }

            return state
        }

        case TeamMembersActionsList.FETCH_TEAM_MEMBERS: {
            return action.data
        }

        case TeamMembersActionsList.WIPE_TEAM_MEMBERS: {
            return []
        }

        case TeamMembersActionsList.DELETE_TEAM_MEMBER: {
            return state.filter(s => s.id !== action.data.id)
        }

        default: {
            return state
        }
    }
}

export default teamMembersReducer
