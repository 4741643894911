import React from 'react'

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    Svg: React.FC
    selected?: boolean
}

const ExportButton = ({ Svg, selected, ...props }: Props) => {
    return <button {...props} className={`mt-export-button ${selected ? 'bg-EFEFEF' : ''} ${props.className ? props.className : ''}`}>
        <Svg />
        <span className='ms-8px'>{props.children}</span>
    </button>
}

export default ExportButton