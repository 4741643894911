import React from 'react'
import Label from './Label'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Radio = ({ children, ...props }: Props) => {
    return <label htmlFor={props.id} className='mt-radio'>
        <div className='mt-radio-button'>
            <input {...props} type='radio' />
            <span className='mt-checkmark' />
        </div>
        <Label htmlFor={props.id} className='ms-8px cursor-pointer'>{children}</Label>
    </label>
}

export default Radio