import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { texasRose, white } from '../config'
import { AuthUser } from '../redux/actions/authUser.actions'
import { StoreState } from '../redux/reducers'
import Chart from './Chart'

interface Props {
    savingsData: number[]
    distanceData: number[]
    labels: string[]
    totalSavings: number
    totalDistance: number
    savingsLabel: string
    distanceLabel: string
}

const Analytics = (props: Props) => {
    const authUser = useSelector<StoreState, AuthUser>(state => state.authUser!)
    const { t } = useTranslation()

    return <div className='row'>
        <div className='col-md-6 col-lg-12'>
            <div className='d-sm-flex d-md-block d-lg-flex justify-content-between align-items-center mb-20px'>
                <div className='fs-15px text-003651 text-truncate'>{t(props.savingsLabel)}</div>
                <div className='fs-20px text-003651 fw-bold text-truncate'>&euro; {props.totalSavings.toFixed(2)}</div>
            </div>
            <Chart
                type='line'
                data={props.savingsData}
                dataSetLabel='Savings'
                labels={props.labels}
                backgroundColor={white}
            />
        </div>

        <div className='d-md-none d-lg-block h-2px bg-EFEFEF my-30px' />

        <div className='col-md-6 col-lg-12'>

            <div className='d-sm-flex d-md-block d-lg-flex justify-content-between align-items-center mb-20px'>
                <div className='fs-15px text-003651'>{t(props.distanceLabel)}</div>
                <div className='fs-20px text-003651 fw-bold'>{props.totalDistance.toFixed(2)} {authUser.measurementType}</div>
            </div>
            <Chart
                type='bar'
                data={props.distanceData}
                dataSetLabel='Distance'
                labels={props.labels}
                backgroundColor={texasRose}
            />
        </div>
    </div>
}

export default Analytics