import React from 'react'

type Props = React.SVGProps<SVGSVGElement>

const ChevronDown = (props: Props) => {
    return <svg {...props} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='6 9 12 15 18 9' />
    </svg>
}

export default ChevronDown
