import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AuthendicationPage from '../components/AuthendicationPage'
import Button from '../components/Button'
import FormInput from '../components/FormInput'
import OverlayLoader from '../components/OverlayLoader'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { api, errorHandler } from '../config'

export interface CreateNewPasswordNavigationState {
    email: string
}

const CreateNewPassword = () => {
    const toast = useToast()
    const navigate = useNavigate()
    const navigationState = useLocation().state as CreateNewPasswordNavigationState
    const { t } = useTranslation()

    const [otp, setOtp] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [confirmPassword, setConfirmPassword] = React.useState<string>('')

    const [otpError, setOtpError] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')
    const [confirmPasswordError, setConfirmPasswordError] = React.useState<string>('')

    const [loginLoading, setLoading] = React.useState<boolean>(false)
    const [showOverlay, setShowOverlay] = React.useState<boolean>(true)

    const otpChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOtp(e.target.value)
        setOtpError('')
    }

    const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
        setConfirmPasswordError('')
    }

    const confirmPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfirmPassword(e.target.value)
        setConfirmPasswordError('')
    }

    const continueClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (otp === '') {
            setOtpError(t('OTP required'))
            error = true
        }

        if (password === '') {
            setPasswordError(t('Password required'))
            error = true
        }

        if (confirmPassword === '') {
            setConfirmPasswordError(t('Re-enter password'))
            error = true
        }

        if (password !== '' && confirmPassword !== '') {
            if (password !== confirmPassword) {
                setConfirmPasswordError(t('Passwords not match'))
                error = true
            }
        } else {
            error = true
        }

        if (!error) {
            const data = {
                otp: otp,
                email: navigationState.email,
                password: password
            }

            setLoading(true)
            api.post('user/forgetPassword', data).then(response => {
                if (response.status === 200) {
                    toast(response.data.message ? t(response.data.message) : t('Password changed'), ToastTypes.SUCCESS)
                    navigate('/login')
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }
            }).catch(error => {
                const text = errorHandler(error, {
                    errorText: 'Unable to change password',
                    showConnectionError: true
                })

                toast(t(text), ToastTypes.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    React.useEffect(() => {
        if (!navigationState.email) {
            navigate('forgot_password')
        } else if (navigationState.email) {
            api.get(`user/user/${navigationState.email}`).then(response => {
                if (response.status !== 200) {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }
            }).catch(() => {
                navigate('forgot_password')
            }).finally(() => {
                setShowOverlay(false)
            })
        }
    }, [])

    return <React.Fragment>
        <OverlayLoader show={showOverlay} />
        <AuthendicationPage headerText={t('Create password')}>
            <form>

                <FormInput
                    label='OTP'
                    type='number'
                    placeholder='OTP'
                    containerClass='mb-24px'
                    errorText={otpError}
                    onChange={otpChangeHandler}
                    value={otp}
                />

                <FormInput
                    label={t('Password')}
                    type='password'
                    placeholder={t('Password')}
                    containerClass='mb-24px'
                    errorText={passwordError}
                    onChange={passwordChangeHandler}
                    value={password}
                />

                <FormInput
                    label={t('Re-enter password')}
                    type='password'
                    placeholder={t('Re-enter password')}
                    containerClass='mb-24px'
                    errorText={confirmPasswordError}
                    onChange={confirmPasswordChangeHandler}
                    value={confirmPassword}
                />

                <Button
                    containerClass='mb-44px'
                    onClick={continueClickHandler}
                    loading={loginLoading}
                >{t('Continue')}</Button>

            </form>

            <div className='fs-14px fw-regular text-prussian-blue-2 text-center mb-8px'>{t('Remember password')}?</div>

            <Link to='/login' className='mt-button mb-44px'>{t('Login')}</Link>

            <div className='fs-14px fw-regular text-prussian-blue-2 text-center mb-8px'>{t('Don\'t have account')}?</div>

            <Link to='/sign_up' className='mt-button'>{t('Sign up')}</Link>
        </AuthendicationPage>
    </React.Fragment>
}

export default CreateNewPassword