import { jsPDF } from 'jspdf'
import { utils, writeFile } from 'xlsx'
import { Trip } from '../redux/actions/trip.actions'
import { blackFade, prussianBlue } from './colors'
import { formatTripDate } from './functions'

export interface ReportExport {
    Date: string
    From: string
    To: string
    Distance: string
    Amount: string
    Purpose: string
    Comments: string
}

const toReportExport = (data: Trip[]) => {
    return data.map<ReportExport>(d => {
        return {
            Date: formatTripDate(d.date),
            From: d.from,
            To: d.to,
            Distance: `${d.distance} ${d.distanceUnit}`,
            Amount: `\u20AC ${d.amount?.toFixed(2)}`,
            Purpose: d.project,
            Comments: d.comments ? d.comments : ' ',
        }
    })
}

export const exportToExcel = (data: Trip[]) => {
    const fileExtension = '.xlsx'
    const sheetName = 'Report'
    const fileName = `${sheetName}-${new Date().getTime()}${fileExtension}`

    const workBook = utils.book_new()
    const workSheet = utils.json_to_sheet(toReportExport(data))

    utils.book_append_sheet(workBook, workSheet, sheetName)

    writeFile(workBook, fileName)
}

export const exportToPdf = (data: Trip[]) => {
    const fileExtension = '.pdf'
    const fileName = `Report-${new Date().getTime()}${fileExtension}`

    const doc = new jsPDF('l', 'mm', 'A4', true)

    doc.setFontSize(10)
    doc.setDrawColor(blackFade)
    doc.setTextColor(prussianBlue)

    let x = 7, y = 10, w = 15, ln = 0
    const h = 9

    const setHeader = () => {
        doc.cell(x, y, w, h, 'S.No', ln, 'c')
        x += w
        w = 40
        doc.cell(x, y, w, h, 'Date', ln, 'c')
        x += w
        w = 45
        doc.cell(x, y, w, h, 'From', ln, 'c')
        x += w
        doc.cell(x, y, w, h, 'To', ln, 'c')
        x += w
        w = 25
        doc.cell(x, y, w, h, 'Distance', ln, 'c')
        x += w
        doc.cell(x, y, w, h, 'Amount', ln, 'c')
        x += w
        w = 45
        doc.cell(x, y, w, h, 'Purpose', ln, 'c')
        x += w
        doc.cell(x, y, w, h, 'Comments', ln, 'c')
    }

    setHeader()

    toReportExport(data).forEach((d, i) => {
        ln += 1
        x = 7
        y += h
        w = 15

        doc.cell(x, y, w, h, `${i + 1}.`, ln, 'c')
        x += w
        w = 40
        doc.cell(x, y, w, h, d.Date, ln, 'c')
        x += w
        w = 45
        doc.cell(x, y, w, h, d.From, ln, 'c')
        x += w
        doc.cell(x, y, w, h, d.To, ln, 'c')
        x += w
        w = 25
        doc.cell(x, y, w, h, d.Distance, ln, 'c')
        x += w
        doc.cell(x, y, w, h, d.Amount, ln, 'c')
        x += w
        w = 45
        doc.cell(x, y, w, h, d.Purpose, ln, 'c')
        x += w
        doc.cell(x, y, w, h, d.Comments, ln, 'c')
    })

    doc.save(fileName)
}