import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { createTeam, CreateTeam, Team, updateTeam } from '../redux/actions/team.actions'
import { AppDispatch } from '../redux/store'
import Button from './Button'
import FormInput from './FormInput'
import { ToastTypes, useToast } from './toast/ToastProvider'

interface Props {
    onCancel?: () => void
    formType?: 'ADD' | 'EDIT'
    teamData?: Team
}

const TeamForm = (props: Props) => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const { t } = useTranslation()
    const dateInputElement = React.useRef<ReactDatePicker<never, undefined>>(null)

    const [name, setName] = React.useState<string>('')
    const [company, setCompany] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')
    const [caption, setCaption] = React.useState<string>('')

    const [nameError, setNameError] = React.useState<string>('')
    const [companyError, setCompanyError] = React.useState<string>('')
    const [descriptionError, setDescriptionError] = React.useState<string>('')
    const [captionError, setCaptionError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const nameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const companyChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCompany(e.target.value)
        setCompanyError('')
    }

    const descriptionChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDescription(e.target.value)
        setDescriptionError('')
    }

    const captionChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCaption(e.target.value)
        setCaptionError('')
    }

    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault()

        let error = false

        if (name.trim() === '') {
            setNameError('Team name required')
            error = true
        } else if (name.trim().length > 15) {
            setNameError('Team name must not exceed 15 characters')
            error = true
        }

        if (company.trim().length > 15) {
            setCompanyError('Company name must not exceed 15 characters')
            error = true
        }

        if (description.trim().length > 100) {
            setDescriptionError('Description must not exceed 100 characters')
            error = true
        }

        if (caption.trim().length > 30) {
            setCaptionError('Caption must not exceed 30 characters')
            error = true
        }

        if (!error) {
            const data: CreateTeam = {
                name: name.trim(),
                caption: caption.trim(),
                company: company.trim(),
                description: description.trim()
            }

            setLoading(true)
            if (props.formType === 'ADD') {
                dispatch(createTeam(data)).then(text => {
                    toast(t(text))
                    if (props.onCancel !== undefined) {
                        props.onCancel()
                    }
                }).catch(error => {
                    toast(t(error), ToastTypes.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else if (props.formType === 'EDIT' && props.teamData !== undefined && props.teamData.id !== undefined) {
                dispatch(updateTeam(data, props.teamData.id)).then(text => {
                    toast(t(text))
                    if (props.onCancel !== undefined) {
                        props.onCancel()
                    }
                }).catch(error => {
                    toast(t(error), ToastTypes.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    // React.useEffect(() => {
    //     if (props.formType === 'EDIT' && props.teamData !== undefined && props.teamData.id !== undefined) {
    //         setDistance(props.teamData.distance.toString())
    //         setDate(formatDate(props.teamData.date))
    //         setFrom(props.teamData.from)
    //         setTo(props.teamData.to)
    //         setPurpose(props.teamData.project)
    //         setComments(props.teamData.comments ? props.teamData.comments : '')
    //     }
    // }, [props.formType, props.teamData])

    return <form>
        <div className='fs-20px fw-bold text-uppercase text-003651 mb-16px text-center'>
            {t(`${props.formType === 'ADD' ? t('Add new') : props.formType === 'EDIT' ? 'Edit' : ''} team`)}
        </div>

        <div className='mt-team-modal-scroll-container'>

            <FormInput
                type='text'
                label={t('Team name')}
                labelRight={`${name.length} / 15`}
                placeholder={t('Enter team name')}
                containerClass='mb-20px'
                value={name}
                errorText={nameError}
                onChange={nameChangeHandler}
                maxLength={15}
            />

            <FormInput
                type='text'
                label={t('Company name')}
                labelRight={`${company.length} / 15`}
                placeholder={t('Enter company name')}
                containerClass='mb-20px'
                value={company}
                errorText={companyError}
                onChange={companyChangeHandler}
                maxLength={15}
            />

            <FormInput
                type='text'
                label={t('Description')}
                labelRight={`${description.length} / 100`}
                placeholder={t('Enter description')}
                containerClass='mb-20px'
                value={description}
                errorText={descriptionError}
                onChange={descriptionChangeHandler}
                maxLength={100}
            />

            <FormInput
                type='text'
                label={t('Caption')}
                labelRight={`${caption.length} / 30`}
                placeholder={t('Enter caption')}
                containerClass='mb-20px'
                value={caption}
                errorText={captionError}
                onChange={captionChangeHandler}
                maxLength={15}
            />

        </div>

        <div className='mt-24px px-22px'>
            <div className='row'>
                <div className={`${props.formType === 'ADD' ? 'col-sm-6' : 'col-6 col-sm-4'}`}>
                    <Button variant='bordered' containerClass='w-100' onClick={props.onCancel} type='button'>{t('Cancel')}</Button>
                </div>
                <div className='d-sm-none h-20px' />
                <div className={`${props.formType === 'ADD' ? 'col-sm-6' : 'col-6 col-sm-4'}`}>
                    <Button
                        type='submit'
                        containerClass='w-100'
                        onClick={saveClickHandler}
                        loading={loading}
                    >
                        {t(`${props.formType === 'ADD' ? 'Add' : props.formType === 'EDIT' ? 'Update' : ''} team`)}
                    </Button>
                </div>
            </div>
        </div>
    </form>
}

export default TeamForm