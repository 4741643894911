import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { TeamContext, TeamContextObj } from '../pages/Teams'
import { inviteTeamMember } from '../redux/actions/teamMembers.actions'
import { AppDispatch } from '../redux/store'
import Button from './Button'
import FormInput from './FormInput'
import { ToastTypes, useToast } from './toast/ToastProvider'

interface Props {
    onCancel?: () => void
}

const InviteTeamMemberForm = (props: Props) => {
    const { t } = useTranslation()
    const { activeTeam } = React.useContext<TeamContextObj | null>(TeamContext)!
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')

    const [inviteLoading, setInviteLoading] = React.useState<boolean>(false)

    const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const inviteClickHandler: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault()
        let error = false

        if (email === '') {
            setEmailError('Email required')
            error = true
        }

        if (!error) {
            const data = {
                email,
                teamId: activeTeam!.id
            }

            setInviteLoading(true)
            dispatch(inviteTeamMember(data)).then(text => {
                toast(text)
                if (props.onCancel) {
                    props.onCancel()
                }
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            }).finally(() => {
                setInviteLoading(false)
            })
        }
    }

    return <form>
        <div className='fs-20px fw-bold text-uppercase text-003651 mb-16px text-center'>{t('Invite team member')}</div>
        <div className='mt-invite-team-member-scroll-container'>
            <FormInput
                label='Email'
                type='email'
                value={email}
                onChange={emailChangeHandler}
                errorText={emailError}
            />
            <div className='mt-24px'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <Button type='button' variant='bordered' containerClass='w-100' onClick={props.onCancel}>{t('Cancel')}</Button>
                    </div>
                    <div className='d-sm-none h-20px' />
                    <div className='col-sm-6'>
                        <Button containerClass='w-100' onClick={inviteClickHandler} loading={inviteLoading}>{t('Invite')}</Button>
                    </div>
                </div>
            </div>
        </div>
    </form>
}

export default InviteTeamMemberForm