import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DailyAnalytics, fetchDailyAnalytics } from '../redux/actions/dailyAnalytics.actions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import Analytics from './Analytics'

interface GraphData {
    date: number
    month: number
    distance: number
    amount: number
}

const Daily = () => {
    const dispatch = useDispatch<AppDispatch>()

    const dailyAnalytics = useSelector<StoreState, DailyAnalytics[]>(state => state.dailyAnalytics)

    const [distanceData, setDistanceData] = React.useState<number[]>([])
    const [savingsData, setSavingsData] = React.useState<number[]>([])
    const [labels, setLabels] = React.useState<string[]>([])

    const totalDistance = React.useMemo(() => {
        return distanceData.reduce((prev, cur) => prev + cur, 0)
    }, [distanceData])

    const totalSavings = React.useMemo(() => {
        return savingsData.reduce((prev, cur) => prev + cur, 0)
    }, [savingsData])

    React.useEffect(() => {
        const dateArray = []

        for (let i = 14; i >= 0; i--) {
            const currentTimestamp = new Date().getTime()
            const dateObj = new Date(currentTimestamp - (i * 86400000))
            dateArray.push({
                date: dateObj.getDate(),
                month: dateObj.getMonth() + 1
            })
        }

        const data = dateArray.map<GraphData>(rm => {
            const index = dailyAnalytics.findIndex(ma => {
                const monthDate = ma.month_date.split('-')
                return rm.month === Number(monthDate[0]) && rm.date === Number(monthDate[1])
            })

            return {
                ...rm,
                amount: index > -1 ? dailyAnalytics[index].amount : 0,
                distance: index > -1 ? dailyAnalytics[index].distance : 0
            }
        })

        setDistanceData(data.map(d => d.distance))
        setSavingsData(data.map(d => d.amount))
        setLabels(dateArray.map(d => d.date.toString()))
    }, [dailyAnalytics])

    React.useEffect(() => {
        dispatch(fetchDailyAnalytics())
    }, [])

    return <Analytics
        distanceData={distanceData}
        labels={labels}
        savingsData={savingsData}
        totalDistance={totalDistance}
        totalSavings={totalSavings}
        savingsLabel='Daily savings'
        distanceLabel='Daily traveled'
    />
}

export default Daily