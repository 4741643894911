import { Reducer } from 'redux'
import { DailyAnalytics, DailyAnalyticsActionsList } from '../actions/dailyAnalytics.actions'
import { Action } from '../actions/types'

const initialValue: DailyAnalytics[] = []

const dailyAnalyticsReducer: Reducer<DailyAnalytics[], Action> = (state: DailyAnalytics[] = initialValue, action: Action): DailyAnalytics[] => {
    switch (action.type) {
        case DailyAnalyticsActionsList.FETCH_DAILY_ANALYTICS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default dailyAnalyticsReducer
