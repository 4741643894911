import { days, monthNames } from '.'

export const nth = (date: number) => {
    if (date > 3 && date < 21) return 'th'
    switch (date % 10) {
        case 1:
            return 'st'
        case 2:
            return 'nd'
        case 3:
            return 'rd'
        default:
            return 'th'
    }
}

export const getDay = (day: number) => {
    return days[day]
}

export const formatDate = (date?: string | number | Date) => {
    let dateObj = new Date()

    if (date !== undefined) {
        dateObj = new Date(date)
    }

    const day = String(dateObj.getDate()).padStart(2, '0')
    const month = String(dateObj.getMonth() + 1).padStart(2, '0')
    const year = dateObj.getFullYear()

    return `${year}-${month}-${day}`
}

export const formatDateInputValue = (input?: string | number | Date) => {
    let dateObj = new Date()

    if (input !== undefined && input !== '') {
        dateObj = new Date(input)
    }

    const weekDay = days[dateObj.getDay()]
    const month = monthNames[dateObj.getMonth()]
    const year = dateObj.getFullYear()
    const date = dateObj.getDate()

    return `${weekDay}, ${month} ${date}${nth(date)}, ${year}`
}

export const formatTripDate = (date: string | number | Date) => {
    const dateObj = new Date(date)

    const dd = dateObj.getDate()
    const month = dateObj.toLocaleDateString('en-US', { month: 'long' })
    const year = dateObj.getFullYear()

    return `${dd}${nth(dd)} ${month} ${year}`
}