import React from 'react'
import { nth } from '../config'
import Card from './Card'

interface Props {
    date: string
    distance?: number
    distanceUnit?: string
    amount?: number
    from?: string
    to?: string
    comments?: string
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const TripCard = (props: Props) => {

    const date = React.useMemo(() => {
        return (() => {
            const dateObj = new Date(props.date)

            const date = dateObj.getDate()
            const month = dateObj.toLocaleDateString('en-US', { month: 'long' })
            const year = dateObj.getFullYear()

            return `${date}${nth(date)} ${month} ${year}`
        })()
    }, [props.date])

    return <Card onClick={props.onClick}>
        <div className='row'>
            <div className='col-6'>
                <span className='fs-16px text-FFAC4D fw-bold'>{date}</span>
            </div>
            <div className='col-3'>
                <span className='fs-14px text-003651 fw-bold'>{props.distance}{props.distanceUnit}</span>
            </div>
            <div className='col-3'>
                <span className='fs-14px text-003651 fw-bold'>&euro;&nbsp;{props.amount?.toFixed(2)}</span>
            </div>
        </div>

        <div className='my-8px h-1px bg-EFEFEF w-100' />

        <div className='hstack'>
            <div className='w-8px'>
                <FromToIcon />
            </div>
            <div className='ms-8px'>
                <div className='fs-14px text-003651'>{props.from}</div>
                <div className='fs-14px text-003651'>{props.to}</div>
            </div>
        </div>

        {props.comments && props.comments !== '' && <React.Fragment>
            <div className='my-8px h-1px bg-EFEFEF w-100' />
            <div className='fs-14px text-678E9F'>{props.comments}</div>
        </React.Fragment>}

    </Card>
}

const FromToIcon = () => {
    return <div className='vstack align-items-center w-8px'>
        <Round
            border='678E9F'
            background='0044663D'
        />
        <VerticalLine background='678E9F' />
        <VerticalLine background='FFFFFF' />
        <VerticalLine background='678E9F' />
        <VerticalLine background='FFFFFF' />
        <VerticalLine background='678E9F' />
        <Round
            border='FFAC4D'
            background='FFAC4D3D'
        />
    </div>
}

interface RoundProps {
    border: string
    background: string
}

const Round = (props: RoundProps) => {
    return <div className={`w-8px h-8px rounded-circle border border-${props.border} bg-${props.background}`} />
}

interface VerticalLineProps {
    background: string
}

const VerticalLine = (props: VerticalLineProps) => {
    return <div className={`h-4px w-1px bg-${props.background}`} />
}

export default TripCard