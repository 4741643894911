import React from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from './Spinner'

interface Props {
    show?: boolean
}

const OverlayLoader = (props: Props) => {
    const { t } = useTranslation()

    return <React.Fragment>
        {props.show && <div className='position-fixed top-0 start-0 vw-100 vh-100 z-index-1000 bg-black-fade-99 d-flex align-items-center justify-content-center'>
            <div className='p-18px br-3px bg-white d-flex align-items-center justify-content-center'>
                <Spinner />
                <span className='d-inline-block ms-10px'>{t('Please wait')}...</span>
            </div>
        </div>}
    </React.Fragment>
}

export default OverlayLoader