import React from 'react'

interface Props {
    children?: React.ReactNode
    visible?: boolean
}

const Modal = (props: Props) => {

    return <React.Fragment>
        {props.visible && <div className='mt-modal'>
            <div className='mt-modal-wrapper'>
                <div className='mt-modal-content'>
                    <ModalChildren>{props.children}</ModalChildren>
                </div>
            </div>
        </div>}
    </React.Fragment>
}

interface ModalChildrenProps {
    children?: React.ReactNode
}

const ModalChildren = (props: ModalChildrenProps) => {
    React.useEffect(() => {
        const body = document.querySelector('body')
        if (body !== null) {
            body.classList.add('overflow-hidden')
        }
        return () => {
            if (body !== null) {
                body.classList.remove('overflow-hidden')
                body.removeAttribute('class')
            }
        }
    }, [])

    return <React.Fragment>{props.children}</React.Fragment>
}

export default Modal