import { Reducer } from 'redux'
import { Action } from '../actions/types'
import { YearlyAnalytics, YearlyAnalyticsActionsList } from '../actions/yearlyAnalytics.actions'

const initialValue: YearlyAnalytics[] = []

const yearlyAnalyticsReducer: Reducer<YearlyAnalytics[], Action> = (state: YearlyAnalytics[] = initialValue, action: Action): YearlyAnalytics[] => {
    switch (action.type) {
        case YearlyAnalyticsActionsList.FETCH_YEARLY_ANALYTICS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default yearlyAnalyticsReducer
