import { Dispatch } from 'redux'
import { api, errorHandler, Response } from '../../config'
import { GetState } from '../reducers'

export enum MonthlyAnalyticsActionsList {
    FETCH_MONTHLY_ANALYTICS = 'FETCH_MONTHLY_ANALYTICS',
}

export interface MonthlyAnalytics {
    amount: number
    count: number
    distance: number
    year_month: string
}

export interface FetchMonthlyAnalyticsAction {
    type: MonthlyAnalyticsActionsList.FETCH_MONTHLY_ANALYTICS
    data: MonthlyAnalytics[]
}

export type MonthlyAnalyticsActions = FetchMonthlyAnalyticsAction

export const fetchMonthlyAnalytics = () => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.get<Response<MonthlyAnalytics[]>>('trip/analytics/monthly', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchMonthlyAnalyticsAction>({
                type: MonthlyAnalyticsActionsList.FETCH_MONTHLY_ANALYTICS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message ? response.data.message : '')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to fetch',
            logout: true,
            showConnectionError: true
        }))
    })
}
