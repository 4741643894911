import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchYearlyAnalytics, YearlyAnalytics } from '../redux/actions/yearlyAnalytics.actions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import Analytics from './Analytics'

interface GraphData {
    year: number
    distance: number
    amount: number
}

const Yearly = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { t } = useTranslation()

    const yearlyAnalytics = useSelector<StoreState, YearlyAnalytics[]>(state => state.yearlyAnalytics)

    const [distanceData, setDistanceData] = React.useState<number[]>([])
    const [savingsData, setSavingsData] = React.useState<number[]>([])
    const [labels, setLabels] = React.useState<string[]>([])

    const totalDistance = React.useMemo(() => {
        return distanceData.reduce((prev, cur) => prev + cur, 0)
    }, [distanceData])

    const totalSavings = React.useMemo(() => {
        return savingsData.reduce((prev, cur) => prev + cur, 0)
    }, [savingsData])

    React.useEffect(() => {
        const yearArray = []

        for (let i = 4; i >= 0; i--) {
            const year = new Date().getFullYear()
            yearArray.push(year - i)
        }

        const data = yearArray.map<GraphData>(year => {
            const index = yearlyAnalytics.findIndex(ma => {
                return year === Number(ma.year)
            })

            return {
                year,
                amount: index > -1 ? yearlyAnalytics[index].amount : 0,
                distance: index > -1 ? yearlyAnalytics[index].distance : 0
            }
        })

        setDistanceData(data.map(d => d.distance))
        setSavingsData(data.map(d => d.amount))
        setLabels(yearArray.map(y => y.toString()))

    }, [yearlyAnalytics])

    React.useEffect(() => {
        dispatch(fetchYearlyAnalytics())
    }, [])

    return <Analytics
        distanceData={distanceData}
        labels={labels}
        savingsData={savingsData}
        totalDistance={totalDistance}
        totalSavings={totalSavings}
        savingsLabel={t('Yearly savings')}
        distanceLabel={t('Yearly traveled')}
    />
}

export default Yearly