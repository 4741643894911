import { Dispatch } from 'redux'
import { api, errorHandler, Response } from '../../config'
import { GetState } from '../reducers'

export enum YearlyAnalyticsActionsList {
    FETCH_YEARLY_ANALYTICS = 'FETCH_YEARLY_ANALYTICS',
}

export interface YearlyAnalytics {
    amount: number
    count: number
    distance: number
    year: string
}

export interface YearlyAnalyticsAction {
    type: YearlyAnalyticsActionsList.FETCH_YEARLY_ANALYTICS
    data: YearlyAnalytics[]
}

export type YearlyAnalyticsActions = YearlyAnalyticsAction

export const fetchYearlyAnalytics = () => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.get<Response<YearlyAnalytics[]>>('trip/analytics/yearly', config).then(response => {
        if (response.status === 200) {
            dispatch<YearlyAnalyticsAction>({
                type: YearlyAnalyticsActionsList.FETCH_YEARLY_ANALYTICS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message ? response.data.message : '')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to fetch',
            logout: true,
            showConnectionError: true
        }))
    })
}
