import React from 'react'

type Props = React.SVGProps<SVGSVGElement>

const LogoSlogan = (props: Props) => {
    return <svg {...props} xmlns='http://www.w3.org/2000/svg' width='120.715' height='60' viewBox='0 0 120.715 60'>
        <g id='Group_2674' data-name='Group 2674' transform='translate(-48 -26)'>
            <g id='Group_2625' data-name='Group 2625' transform='translate(33.566 17.862)'>
                <g id='Group_2557' data-name='Group 2557' transform='translate(14.434 8.138)'>
                    <rect id='Rectangle_1687' data-name='Rectangle 1687' width='38.841' height='38.848' rx='19.421' transform='translate(6.108 6.134)' fill='#fff' />
                    <g id='Group_2555' data-name='Group 2555' transform='translate(8.039 22.644)'>
                        <g id='Group_2556' data-name='Group 2556' transform='translate(0 0)'>
                            <path id='Subtraction_4' data-name='Subtraction 4' d='M17.6,11.894h-.172A18.561,18.561,0,0,1,6.794,8.553,18.836,18.836,0,0,1,0,0H35.027a18.843,18.843,0,0,1-6.793,8.551A18.549,18.549,0,0,1,17.6,11.894Z' transform='translate(0 10.346)' fill='rgba(11,31,41,0.28)' />
                            <path id='Path_7550' data-name='Path 7550' d='M20.833-.361H16.025a.819.819,0,0,1-.819-.819A.819.819,0,0,1,16.025-2h4.808a.819.819,0,0,1,.819.819A.819.819,0,0,1,20.833-.361Zm-8.5,0H7.422A.819.819,0,0,1,6.6-1.181.819.819,0,0,1,7.422-2h4.916a.819.819,0,0,1,.819.819A.819.819,0,0,1,12.338-.361Zm-8.6,0H-1.181A.819.819,0,0,1-2-1.181.819.819,0,0,1-1.181-2H3.735a.819.819,0,0,1,.819.819A.819.819,0,0,1,3.735-.361Z' transform='translate(7.543 16.802)' fill='#fff' />
                            <g id='Group_2622' data-name='Group 2622' transform='translate(4.989)'>
                                <g id='Group_2619' data-name='Group 2619' transform='translate(0)'>
                                    <path id='Union_16' data-name='Union 16' d='M16.825,10.672a1.942,1.942,0,1,1,1.942,1.942A1.943,1.943,0,0,1,16.825,10.672Zm-12.815,0a1.942,1.942,0,1,1,1.942,1.942A1.943,1.943,0,0,1,4.01,10.672Zm12.2.436H8.506a2.59,2.59,0,1,0-5.144-.435,2.57,2.57,0,0,0,.037.435H.546c-.125,0-.536-.025-.546-.35a.357.357,0,0,1,.1-.27.614.614,0,0,1,.346-.154V7.068A2.1,2.1,0,0,1,2.215,5.12L3.981,1.27A2.377,2.377,0,0,1,6.172,0h7.437A2.489,2.489,0,0,1,15.22.545c.033.028.122.085.258.171l.006,0a12.115,12.115,0,0,1,2.343,1.838,12.922,12.922,0,0,1,1.78,2.492c.175.027,4.275.677,5.346,2.019a2.764,2.764,0,0,1,.407,2.288,5.18,5.18,0,0,1-.424,1.246c0,.27-.566.5-.59.505H21.321a2.59,2.59,0,1,0-5.144-.435,2.561,2.561,0,0,0,.037.435h0Zm7.231-4.14a1.907,1.907,0,0,0-.21,1.332,1.52,1.52,0,0,0,1.3,1.182.4.4,0,0,0,.426-.351,2.075,2.075,0,0,0-.452-1.789c-.3-.338-.566-.509-.779-.509A.363.363,0,0,0,23.444,6.968ZM11.216,5.049h6.347a6.5,6.5,0,0,0-2.426-2.38,9.041,9.041,0,0,0-3.6-.654c-.164,0-.276,0-.312.005h-.012ZM6.172,2.668a4.472,4.472,0,0,0-1.415,2.38H9.9V2.02s-.131-.006-.354-.006A7.074,7.074,0,0,0,6.172,2.668Z' fill='#0b1f29' />
                                </g>
                            </g>
                        </g>
                    </g>
                    <path id='Subtraction_8' data-name='Subtraction 8' d='M25.5,60h0A93.286,93.286,0,0,1,12.75,49.806,55.646,55.646,0,0,1,3.984,39.3C1.34,35.042,0,30.965,0,27.186A30.949,30.949,0,0,1,2,15.948,25.235,25.235,0,0,1,7.468,7.38,24.914,24.914,0,0,1,25.5,0,24.916,24.916,0,0,1,43.531,7.38,25.237,25.237,0,0,1,49,15.948a30.946,30.946,0,0,1,2,11.238c0,3.779-1.34,7.856-3.985,12.117a55.663,55.663,0,0,1-8.766,10.5A93.287,93.287,0,0,1,25.5,60Zm0-52.9A18.706,18.706,0,1,0,44.049,25.8,18.649,18.649,0,0,0,25.5,7.1Z' transform='translate(-0.001 0)' fill='#ffac4d' />
                </g>
            </g>
            <path id='Path_7600' data-name='Path 7600' d='M1.416,0V-14.316H5.742l2.6,9.766,2.568-9.766h4.336V0H12.559V-11.27L9.717,0H6.934L4.1-11.27V0ZM18.027,0V-14.316h2.891V0Zm8.867,0V-11.895H22.646v-2.422H34.023v2.422H29.785V0Zm20.42,0H44.17L42.92-3.252H37.2L36.016,0H32.949l5.576-14.316h3.057ZM41.992-5.664,40.02-10.977,38.086-5.664ZM50.586,0V-11.895H46.338v-2.422H57.715v2.422H53.477V0Z' transform='translate(111 68)' fill='#003651' />
        </g>
    </svg>
}

export default LogoSlogan