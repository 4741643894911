import { Reducer } from 'redux'
import { Team, TeamActionsList } from '../actions/team.actions'
import { Action } from '../actions/types'

const initialValue: Team[] = []

const teamReducer: Reducer<Team[], Action> = (state: Team[] = initialValue, action: Action): Team[] => {
    switch (action.type) {
        case TeamActionsList.ADD_TEAM: {
            return [
                action.data,
                ...state
            ]
        }

        case TeamActionsList.UPDATE_TEAM: {
            const teams = [...state]
            const index = teams.findIndex(t => t.id === action.data.id)

            if (index > -1) {
                teams[index] = action.data
            }

            return teams
        }

        case TeamActionsList.DELETE_TEAM: {
            return state.filter(s => s.id !== action.data.id)
        }

        case TeamActionsList.FETCH_TEAMS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default teamReducer
