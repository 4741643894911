import React from 'react'
import { TeamContext, TeamContextObj } from '../pages/Teams'
import { Team } from '../redux/actions/team.actions'
import Card from './Card'

type Props = Team

const TeamCard = (props: Props) => {
    const { setActiveTeam } = React.useContext<TeamContextObj | null>(TeamContext)!

    const cardClickHandler = () => {
        setActiveTeam(props)
    }

    return <Card onClick={cardClickHandler}>
        <div className='fs-16px text-dark-blue fw-medium'>{props.name}</div>
    </Card>
}

export default TeamCard