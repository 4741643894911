import React from 'react'
import { useSelector } from 'react-redux'
import { Trip } from '../redux/actions/trip.actions'
import { StoreState } from '../redux/reducers'
import Modal from './Modal'
import TripCard from './TripCard'
import TripForm from './TripForm'

const Trips = () => {
    const trips = useSelector<StoreState, Trip[]>(state => state.trips)

    const [showTripModal, setShowTripModal] = React.useState<boolean>(false)
    const [editTripData, setEditTripData] = React.useState<Trip>()

    const cancelClickHandler = () => {
        setShowTripModal(false)
        setEditTripData(undefined)
    }

    return <React.Fragment>
        <Modal visible={showTripModal}>
            <TripForm
                formType='EDIT'
                onCancel={cancelClickHandler}
                tripData={editTripData}
            />
        </Modal>
        <div className='row'>
            {trips.map(trip => {
                const onClick = () => {
                    setShowTripModal(true)
                    setEditTripData(trip)
                }

                return <TripComponent
                    amount={trip.amount}
                    comments={trip.comments ? trip.comments : ''}
                    date={trip.date}
                    distance={trip.distance}
                    distanceUnit={trip.distanceUnit}
                    from={trip.from}
                    onClick={onClick}
                    to={trip.to}
                    key={trip.id}
                />
            })}
        </div>
    </React.Fragment>
}

interface TripProps {
    amount?: number
    comments: string
    date: string
    distance: number
    distanceUnit?: string
    from: string
    to: string
    onClick: React.MouseEventHandler<HTMLDivElement>
}

const TripComponent = (props: TripProps) => {
    return <div className='col-sm-8 col-md-6 col-lg-12 col-xl-6 col-xxl-4 my-8px'>
        <TripCard
            amount={props.amount}
            comments={props.comments}
            date={props.date}
            distance={props.distance}
            distanceUnit={props.distanceUnit}
            from={props.from}
            to={props.to}
            onClick={props.onClick}
        />
    </div>
}

export default Trips