import AnalyticsContainer from '../components/AnalyticsContainer'
import BaseLayout from '../components/BaseLayout'
import Report from '../components/Report'

const Home = () => {
    return <BaseLayout showAddNewTrip showTeams>
        <div className='row'>
            <div className='col-lg-6 col-xl-5 col-xxl-4'>
                <AnalyticsContainer />
            </div>
            <div className='col-lg-6 col-xl-7 col-xxl-8'>
                <Report />
            </div>
        </div>
    </BaseLayout>
}

export default Home