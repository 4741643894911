import React from 'react'
import Header from './Header'

interface Props {
    children?: React.ReactNode
    showAddNewTrip?: boolean
    showTeams?: boolean
}

const BaseLayout = (props: Props) => {
    return <div className='home bg-white'>
        <Header showAddNewTrip={props.showAddNewTrip} showTeams={props.showTeams} />
        <div className='mt-home-container'>{props.children}</div>
    </div>
}

export default BaseLayout