import { Dispatch } from 'redux'

export enum NetworkErrorActionsList {
    UPDATE_NETWORK_ERROR = 'UPDATE_NETWORK_ERROR'
}

export interface UpdateNetworkErrorAction {
    type: NetworkErrorActionsList.UPDATE_NETWORK_ERROR
    data: boolean
}

export const updateNetworkError = (data: boolean) => async (dispatch: Dispatch) => {
    dispatch<UpdateNetworkErrorAction>({
        type: NetworkErrorActionsList.UPDATE_NETWORK_ERROR,
        data: data
    })
}
