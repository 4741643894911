import { Reducer } from 'redux'
import { NetworkErrorActionsList } from '../actions/networkError.actions'
import { Action } from '../actions/types'

const initialValue = false

const networkErrorReducer: Reducer<boolean, Action> = (state: boolean = initialValue, action: Action): boolean => {
    switch (action.type) {
        case NetworkErrorActionsList.UPDATE_NETWORK_ERROR: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default networkErrorReducer
