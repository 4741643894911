import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../redux/actions/authUser.actions'
import { AppDispatch } from '../redux/store'
import SignOut from './svg/SignOut'
import User from './svg/User'
import { useToast } from './toast/ToastProvider'

interface Props {
    innerRef?: React.LegacyRef<HTMLDivElement>
    onClickAccountSettings?: React.MouseEventHandler<HTMLAnchorElement>
}

const SettingsModal = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const { t } = useTranslation()

    const logoutClickHandler: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        toast(`${t('Signed out')}!`)
        dispatch(logout())
    }

    return <div ref={props.innerRef}>
        <div className='mt-triangle' />
        <div className='mt-settings-modal'>
            <Link className='mt-settings-modal-item hstack gap-2' to='#' onClick={props.onClickAccountSettings}>
                <User width={16} height={16} />
                <span>{t('Account settings')}</span>
            </Link>
            <Link className='mt-settings-modal-item hstack gap-2' to='#' onClick={logoutClickHandler}>
                <SignOut />
                <span>{t('Sign out')}</span>
            </Link>
        </div>
    </div>
}

export default SettingsModal