import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTeams, Team } from '../redux/actions/team.actions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import Modal from './Modal'
import PlusCircle from './svg/PlusCircle'
import TeamCard from './TeamCard'
import TeamForm from './TeamForm'

const TeamList = () => {
    const dispatch = useDispatch<AppDispatch>()
    const teams = useSelector<StoreState, Team[]>(state => state.teams)

    const [showAddNewTeam, setShowAddNewTeam] = React.useState<boolean>(false)

    const addNewTeamClickHandler = () => {
        setShowAddNewTeam(true)
    }

    React.useEffect(() => {
        dispatch(fetchTeams())
    }, [])

    return <React.Fragment>
        <Modal visible={showAddNewTeam}>
            <TeamForm
                formType='ADD'
                onCancel={() => setShowAddNewTeam(false)}
            />
        </Modal>
        <div className='container-fluid px-3'>
            <div className='d-flex align-items-center justify-content-between  mb-32px'>
                <div className='fs-20px fw-bold text-uppercase text-003651'>Teams</div>
                <PlusCircle className='cursor-pointer' onClick={addNewTeamClickHandler} />
            </div>
            <div className='row'>
                {teams.map(team => {
                    return <TeamComponent {...team} key={team.id} />
                })}
            </div>
        </div>
    </React.Fragment>
}

type TeamComponentProps = Team

const TeamComponent = (props: TeamComponentProps) => {
    return <div className='col-6'>
        <div className='my-1'>
            <TeamCard {...props} />
        </div>
    </div>
}

export default TeamList 