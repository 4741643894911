import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import BaseLayout from '../components/BaseLayout'
import Button from '../components/Button'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { api, errorHandler, getAxiosRequestConfig, Response } from '../config'
import { AuthUser } from '../redux/actions/authUser.actions'
import { StoreState } from '../redux/reducers'

export type InvitaitonParams = {
    token: string
}

const Invitation = () => {
    const params = useParams<InvitaitonParams>()
    const user = useSelector<StoreState, AuthUser>(state => state.authUser!)
    const config = getAxiosRequestConfig(user['auth-token'])
    const toast = useToast()
    const navigate = useNavigate()

    const [joining, setJoining] = React.useState<boolean>(false)

    const joinClickHandler: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (params.token) {
            const data = {
                token: params.token
            }

            setJoining(true)
            api.put<Response>('teamMember/join', data, config).then(response => {
                if (response.status === 200) {
                    toast(response.data.message!)
                    navigate('/teams')
                } else {
                    throw { response }
                }
            }).catch(error => {
                const errorText = errorHandler(error, {
                    errorText: 'Unble to join',
                    showConnectionError: true
                })

                toast(errorText, ToastTypes.ERROR)
            }).finally(() => {
                setJoining(false)
            })
        }
    }

    return <BaseLayout>
        <div className='container'>
            <div className='d-flex align-items-center justify-content-center'>
                <Button onClick={joinClickHandler} loading={joining}>Join</Button>
            </div>
        </div>
    </BaseLayout>
}

export default Invitation