import { AxiosRequestConfig } from 'axios'
import React from 'react'
import { useSelector } from 'react-redux'
import { api, errorHandler, exportToExcel, exportToPdf, Response } from '../config'
import { Trip } from '../redux/actions/trip.actions'
import { StoreState } from '../redux/reducers'
import Button from './Button'
import Container from './Container'
import DateInput from './DateInput'
import ExportButton from './ExportButton'
import Label from './Label'
// import Csv from './svg/Csv'
import Excel from './svg/Excel'
// import Pdf from './svg/Pdf'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import useDimension from '../hooks/useDimension'
import FormInput from './FormInput'
import Modal from './Modal'
import Briox from './svg/Briox'
import { ToastTypes, useToast } from './toast/ToastProvider'
import Trips from './Trips'

type ExportType = 'Briox' | 'Excel' | 'Pdf' | 'Csv'

const Report = () => {
    const toast = useToast()
    const { t } = useTranslation()
    const { width } = useDimension()
    const fromDateInputElement = React.useRef<ReactDatePicker<never, undefined>>(null)
    const toDateInputElement = React.useRef<ReactDatePicker<never, undefined>>(null)

    const axiosRequestConfig = useSelector<StoreState, AxiosRequestConfig>(state => state.axiosRequestConfig!)

    const [exportType, setExportType] = React.useState<ExportType[]>([])
    const [fromDate, setFromDate] = React.useState<string>('')
    const [fromDateFormatted, setFromDateFormatted] = React.useState<string>('')
    const [toDate, setToDate] = React.useState<string>('')
    const [toDateFormatted, setToDateFormatted] = React.useState<string>('')
    const [showFromDateCalendar, setShowFromDateCalendar] = React.useState<boolean>(false)
    const [showToDateCalendar, setShowToDateCalendar] = React.useState<boolean>(false)

    const [fromDateError, setFromDateError] = React.useState<string>('')
    const [toDateError, setToDateError] = React.useState<string>('')

    const [reportLoading, setReportLoading] = React.useState<boolean>(false)
    const [brioxReportLoading, setBrioxReportLoading] = React.useState<boolean>(false)
    const [showBrioxAccountIdModal, setShowBrioxAccountIdModal] = React.useState<boolean>(false)
    const [brioxAccountId, setBrioxAccountId] = React.useState<string>('')
    const [brioxAccountIdError, setBrioxAccountIdError] = React.useState<string>('')

    const isExportTypeSelected = (exportTypeArray: ExportType[], exportType: ExportType) => {
        return exportTypeArray.indexOf(exportType) > -1
    }

    const updateExportType = (exportTypeArray: ExportType[], exportType: ExportType) => {
        return isExportTypeSelected(exportTypeArray, exportType)
            ? exportTypeArray.filter(et => et !== exportType)
            : [
                ...exportTypeArray,
                exportType
            ]
    }

    const brioxClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        setExportType(prev => updateExportType(prev, 'Briox'))
    }

    const excelClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        setExportType(prev => updateExportType(prev, 'Excel'))
    }

    // const pdfClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    //     setExportType(prev => updateExportType(prev, 'Pdf'))
    // }

    // const csvClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    //     setExportType(prev => updateExportType(prev, 'Csv'))
    // }

    const fromDateChangeHandler = (date: string, dateFormatted: string) => {
        setFromDate(date)
        setFromDateFormatted(dateFormatted)
        setFromDateError('')
        setShowFromDateCalendar(false)
    }

    const fromDateFocusHandler: React.FocusEventHandler<HTMLInputElement> = () => {
        setShowFromDateCalendar(true)
    }

    const fromDateClickHandler: React.MouseEventHandler<SVGSVGElement> = () => {
        if (fromDateInputElement.current !== null) {
            fromDateInputElement.current.setFocus()
        }
    }

    const fromDateClickOutsideHandler: React.MouseEventHandler<HTMLDivElement> = () => {
        setShowFromDateCalendar(false)
    }

    const fromDateBlurHandler: React.FocusEventHandler<HTMLInputElement> = () => {
        setShowFromDateCalendar(false)
    }

    const toDateChangeHandler = (date: string, dateFormatted: string) => {
        setToDate(date)
        setToDateFormatted(dateFormatted)
        setToDateError('')
        setShowToDateCalendar(false)
    }

    const toDateFocusHandler: React.FocusEventHandler<HTMLInputElement> = () => {
        setShowToDateCalendar(true)
    }

    const toDateClickHandler: React.MouseEventHandler<SVGSVGElement> = () => {
        if (toDateInputElement.current !== null) {
            toDateInputElement.current.setFocus()
        }
    }

    const toDateClickOutsideHandler: React.MouseEventHandler<HTMLDivElement> = () => {
        setShowToDateCalendar(false)
    }

    const toDateBlurHandler: React.FocusEventHandler<HTMLInputElement> = () => {
        setShowToDateCalendar(false)
    }

    const brioxAccountIdOkChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setBrioxAccountId(e.target.value)
        setBrioxAccountIdError('')
    }

    const reportClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        let error = false

        if (fromDate === '') {
            setFromDateError(t('Select start date'))
            error = true
        }

        if (toDate === '') {
            setToDateError(t('Select end date'))
            error = true
        }

        if (exportType.length <= 0) {
            toast(t('Select export type'), ToastTypes.ERROR)
            error = true
        }

        if (!error) {
            if (exportType.indexOf('Briox') > -1) {
                setShowBrioxAccountIdModal(true)
            }

            setReportLoading(true)
            api.get<Response<Trip[]>>(`trip/filter/${fromDateFormatted}/${toDateFormatted}`, axiosRequestConfig).then(response => {
                if (response.status === 200) {
                    if (response.data.data.length > 0) {
                        if (exportType.indexOf('Excel') > -1) {
                            exportToExcel(response.data.data)
                        }

                        if (exportType.indexOf('Pdf') > -1) {
                            exportToPdf(response.data.data)
                        }
                    } else {
                        toast('No trips found', ToastTypes.ERROR)
                    }
                } else {
                    throw { response }
                }
            }).catch(error => {
                errorHandler(error, {
                    showConnectionError: true
                })
            }).finally(() => {
                setReportLoading(false)
            })
        }
    }

    const brioxAccountIdCancelClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        setShowBrioxAccountIdModal(false)
        setBrioxAccountId('')
    }

    const brioxAccountIdOkClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        let error = false

        if (brioxAccountId.trim() === '') {
            setBrioxAccountIdError('Briox account id required')
            error = true
        }

        if (!error) {
            setBrioxReportLoading(true)
            const data = {
                fromDate: fromDateFormatted,
                toDate: toDateFormatted,
                brioxAccountId
            }

            api.post<Response>('trip/briox/export', data, axiosRequestConfig).then(response => {
                if (response.status === 200) {
                    setShowBrioxAccountIdModal(false)
                    setBrioxAccountId('')
                    toast('Briox report exported')
                } else {
                    throw { response }
                }
            }).catch(error => {
                const errorText = errorHandler(error, {
                    showConnectionError: true,
                    errorText: 'Unable to export briox report'
                })

                toast(errorText, ToastTypes.ERROR)
            }).finally(() => {
                setBrioxReportLoading(false)
            })
        }
    }

    return <React.Fragment>
        <Modal visible={showBrioxAccountIdModal}>
            <div className='w-300px px-30px'>
                <div className='fs-16px fw-bold text-uppercase text-003651 mb-16px text-center'>Briox account id</div>
                <div className='vstack gap-3'>
                    <FormInput
                        type='number'
                        value={brioxAccountId}
                        onChange={brioxAccountIdOkChangeHandler}
                        errorText={brioxAccountIdError}
                    />
                    <div className='hstack gap-2'>
                        <Button onClick={brioxAccountIdCancelClickHandler} variant='bordered' containerClass='w-100'>{t('Cancel')}</Button>
                        <Button onClick={brioxAccountIdOkClickHandler} containerClass='w-100' loading={brioxReportLoading}>{t('Ok')}</Button>
                    </div>
                </div>
            </div>
        </Modal>
        <Container>
            <div className='px-22px'>
                <div className='fs-20px fw-bold text-uppercase text-003651 mb-32px'>{t('Edit and report')}</div>
                <Label className='mb-12px'>{t('Export report')}:</Label>

                <div className='hstack gap-3 mb-24px'>

                    <ExportButton
                        Svg={Briox}
                        selected={isExportTypeSelected(exportType, 'Briox')}
                        onClick={brioxClickHandler}
                    >Briox</ExportButton>

                    <ExportButton
                        Svg={Excel}
                        selected={isExportTypeSelected(exportType, 'Excel')}
                        onClick={excelClickHandler}
                    >Excel</ExportButton>

                    {/* <ExportButton
                    Svg={Pdf}
                    selected={isExportTypeSelected(exportType, 'Pdf')}
                    onClick={pdfClickHandler}
                >Pdf</ExportButton>

                <ExportButton
                    Svg={Csv}
                    selected={isExportTypeSelected(exportType, 'Csv')}
                    onClick={csvClickHandler}
                >Csv</ExportButton> */}

                </div>

                <Label className='mb-8px'>{t('Reporting period')}:</Label>

                <div className='row'>
                    <div className='col-sm-6 col-lg-12 col-xl-6'>
                        <DateInput
                            placeholder={t('Select start date')}
                            onChange={fromDateChangeHandler}
                            value={fromDate}
                            maxDate={new Date()}
                            errorText={fromDateError}
                            onFocus={fromDateFocusHandler}
                            onBlur={fromDateBlurHandler}
                            open={showFromDateCalendar}
                            onClick={fromDateClickHandler}
                            onClickOutside={fromDateClickOutsideHandler}
                            innerRef={fromDateInputElement}
                        />
                    </div>
                    <div className='d-sm-none d-lg-block d-xl-none h-20px' />
                    <div className='col-sm-6 col-lg-12 col-xl-6'>
                        <DateInput
                            placeholder={t('Select end date')}
                            onChange={toDateChangeHandler}
                            value={toDate}
                            maxDate={new Date()}
                            errorText={toDateError}
                            onFocus={toDateFocusHandler}
                            onBlur={toDateBlurHandler}
                            open={showToDateCalendar}
                            onClick={toDateClickHandler}
                            onClickOutside={toDateClickOutsideHandler}
                            innerRef={toDateInputElement}
                        />
                    </div>
                </div>
            </div>

            <div className='w-100 bg-EFEFEF h-1px mt-18px' />

            <div className='px-22px'>

                <div className='my-15px d-flex justify-content-end'>
                    <Button containerClass={width < 576 ? 'w-100' : ''} className='px-48px' onClick={reportClickHandler} loading={reportLoading}>{t('Report')}</Button>
                </div>
            </div>

            <div className='mt-report-scroll-container'>
                <Trips />
            </div>

        </Container>
    </React.Fragment>
}

export default Report