import { Reducer } from 'redux'
import { MonthlyAnalytics, MonthlyAnalyticsActionsList } from '../actions/monthlyAnalytics.actions'
import { Action } from '../actions/types'

const initialValue: MonthlyAnalytics[] = []

const monthlyAnalyticsReducer: Reducer<MonthlyAnalytics[], Action> = (state: MonthlyAnalytics[] = initialValue, action: Action): MonthlyAnalytics[] => {
    switch (action.type) {
        case MonthlyAnalyticsActionsList.FETCH_MONTHLY_ANALYTICS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default monthlyAnalyticsReducer
