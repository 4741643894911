import React from 'react'
import Input from './Input'
import Label from './Label'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    containerClass?: string
    label?: string
    labelRight?: string
    errorText?: string
}

const FormInput = ({ containerClass, label, labelRight, errorText, ...props }: Props) => {
    const isErrorExists = errorText !== undefined && errorText !== ''

    return <div className={`position-relative ${containerClass ? containerClass : ''}`}>
        <div className='d-flex align-items-center justify-content-betweeen'>
            <Label className='ps-25px mb-2px w-100 d-inline-block'>{label}</Label>
            <Label className='pe-25px mb-2px w-100 d-inline-block text-end'>{labelRight}</Label>
        </div>
        <Input {...props} className={`${isErrorExists ? 'border-mandy' : ''} ${props.className ? props.className : ''}`} />
        {isErrorExists && <label className='fs-12px ps-24px w-100 d-inline-block text-mandy fw-semi-bold position-absolute top-100 start-0 fade-in lh-14px'>{errorText}</label>}
    </div>
}

export default FormInput