import { Reducer } from 'redux'
import { AuthUser, AuthUserActionsList } from '../actions/authUser.actions'
import { Action } from '../actions/types'

const initialValue: AuthUser | null = null

const authUserReducer: Reducer<AuthUser | null, Action> = (state: AuthUser | null = initialValue, action: Action): AuthUser | null => {
    switch (action.type) {
        case AuthUserActionsList.LOGIN_USER: {
            return action.data
        }

        case AuthUserActionsList.UPDATE_USER: {
            return action.data
        }

        case AuthUserActionsList.LOGOUT_USER: {
            return initialValue
        }

        default: {
            return state
        }
    }
}

export default authUserReducer
