import React from 'react'
import { useSelector } from 'react-redux'
import { TeamContext, TeamContextObj } from '../pages/Teams'
import { AuthUser } from '../redux/actions/authUser.actions'
import { TeamMember } from '../redux/actions/teamMembers.actions'
import { StoreState } from '../redux/reducers'
import Card from './Card'

type Props = TeamMember

const TeamMemberCard = (props: Props) => {
    const { setActiveTeamMember, activeTeam } = React.useContext<TeamContextObj | null>(TeamContext)!
    const teamMembers = useSelector<StoreState, TeamMember[]>(state => state.teamMembers)
    const authUser = useSelector<StoreState, AuthUser>(state => state.authUser!)

    const isOwner = activeTeam !== null && activeTeam.ownerId === authUser.userId
    const isManager = (() => {
        const index = teamMembers.findIndex(tm => tm.user.id === authUser.userId)
        return activeTeam !== null && index > -1 && teamMembers[index].role === 'manager'
    })()

    const cardClickHandler = () => {
        if (isOwner || isManager) {
            setActiveTeamMember(props)
        }
    }

    return <Card onClick={cardClickHandler}>
        <div className='fs-16px text-dark-blue fw-medium'>{authUser.userId === props.user.id ? 'You' : props.user.name}</div>
        <div className='fs-16px text-dark-blue fw-medium'>{props.user.email}</div>
        <div className='fs-16px text-dark-blue fw-medium'>{props.role}</div>
        <div className='fs-16px text-dark-blue fw-medium'>{props.status}</div>
    </Card>
}

export default TeamMemberCard