import { combineReducers } from 'redux'
import { AuthUserActionsList } from '../actions/authUser.actions'
import { Action } from '../actions/types'
import authUserReducer from './authUser.reducer'
import axiosRequestConfigReducer from './axiosRequestConfig.reducer'
import dailyAnalyticsReducer from './dailyAnalytics.reducer'
import languageReducer from './language.reducer'
import monthlyAnalyticsReducer from './monthlyAnalytics.reducer'
import networkErrorReducer from './networkError.reducer'
import teamReducer from './team.reducer'
import teamMembersReducer from './teamMembers.reducer'
import tripReducer from './trip.reducer'
import yearlyAnalyticsReducer from './yearlyAnalytics.reducer'

const combinedReducers = combineReducers({
    authUser: authUserReducer,
    axiosRequestConfig: axiosRequestConfigReducer,
    dailyAnalytics: dailyAnalyticsReducer,
    language: languageReducer,
    teams: teamReducer,
    teamMembers: teamMembersReducer,
    trips: tripReducer,
    monthlyAnalytics: monthlyAnalyticsReducer,
    yearlyAnalytics: yearlyAnalyticsReducer,
    networkError: networkErrorReducer
})

const reducers = (state: StoreState | undefined, action: Action) => {
    if (action.type === AuthUserActionsList.LOGOUT_USER) {
        state = undefined
    }

    return combinedReducers(state, action)
}

export type StoreState = ReturnType<typeof combinedReducers>
export type GetState = () => StoreState

export default reducers