export const gallery = '#EFEFEF'
export const white = '#FFFFFF'
export const whiteFade = '#FFFFFF2E'
export const prussianBlue = '#003149'
export const prussianBlueFade = '#0031490A'
export const prussianBlue2 = '#003651'
export const prussianBlue2Fade = '#0036510A'
export const prussianBlue2Fade1 = '#0036513D'
export const texasRose = '#FFAC4D'
export const texasRoseFade = '#FFAC4D3D'
export const silverChalice = '#9E9E9E'
export const silverChaliceFade = '#9E9E9ECC'
export const gray = '#888888'
export const gothic = '#678E9F'
export const mandy = '#E2574C'
export const tallPoppy = '#B53629'
export const chateauGreen = '#45B058'
export const seaGreen = '#349C42'
export const fireflyFade = '#0B1F2947'
export const firefly = '#0B1F29'
export const blackFade = '#00000029'
export const regalBlue = '#004466'
export const regalBlueFade = '#0044663D'
export const alto = '#D0D0D0'
export const mineShaft = '#3B3B3B'
