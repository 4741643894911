import { AxiosRequestConfig } from 'axios'
import { Reducer } from 'redux'
import { getAxiosRequestConfig } from '../../config'
import { AxiosRequestConfigActionsList } from '../actions/axiosRequestConfig.actions'
import { Action } from '../actions/types'

const initialValue: AxiosRequestConfig | null = null

const axiosRequestConfigReducer: Reducer<AxiosRequestConfig | null, Action> = (state: AxiosRequestConfig | null = initialValue, action: Action): AxiosRequestConfig | null => {
    switch (action.type) {
        case AxiosRequestConfigActionsList.ADD_AXIOS_REQUEST_CONFIG: {
            return getAxiosRequestConfig(action.data)
        }

        case AxiosRequestConfigActionsList.REMOVE_AXIOS_REQUEST_CONFIG: {
            return initialValue
        }

        default: {
            return state
        }
    }
}

export default axiosRequestConfigReducer
