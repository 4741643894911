import React from 'react'

type Props = React.SVGProps<SVGSVGElement>

const Logo = (props: Props) => {
    return <svg {...props} xmlns='http://www.w3.org/2000/svg' width='112' height='132' viewBox='0 0 112 132'>
        <g id='Group_2625' data-name='Group 2625' transform='translate(-14.434 -8.114)'>
            <g id='Group_2557' data-name='Group 2557' transform='translate(14.434 8.138)'>
                <rect id='Rectangle_1687' data-name='Rectangle 1687' width='85.435' height='85.45' rx='42.717' transform='translate(13.437 13.493)' fill='#fff' />
                <g id='Group_2555' data-name='Group 2555' transform='translate(17.684 49.809)'>
                    <g id='Group_2556' data-name='Group 2556' transform='translate(0 0)'>
                        <path id='Subtraction_4' data-name='Subtraction 4' d='M38.987,26.163H38.07a40.861,40.861,0,0,1-23.248-7.42,41.674,41.674,0,0,1-8.767-8.254A41.261,41.261,0,0,1-.006,0H77.052a41.266,41.266,0,0,1-6.061,10.488,41.689,41.689,0,0,1-8.766,8.254A40.839,40.839,0,0,1,38.987,26.163Z' transform='translate(0 22.757)' fill='rgba(11,31,41,0.28)' />
                        <path id='Path_7550' data-name='Path 7550' d='M48.224,1.6H37.647a1.8,1.8,0,0,1-1.8-1.8,1.8,1.8,0,0,1,1.8-1.8H48.224a1.8,1.8,0,0,1,1.8,1.8A1.8,1.8,0,0,1,48.224,1.6Zm-18.686,0H18.725a1.8,1.8,0,0,1-1.8-1.8,1.8,1.8,0,0,1,1.8-1.8H29.537a1.8,1.8,0,0,1,1.8,1.8A1.8,1.8,0,0,1,29.537,1.6Zm-18.922,0H-.2A1.8,1.8,0,0,1-2-.2,1.8,1.8,0,0,1-.2-2H10.615a1.8,1.8,0,0,1,1.8,1.8A1.8,1.8,0,0,1,10.615,1.6Z' transform='translate(14.192 34.558)' fill='#fff' />
                        <g id='Group_2622' data-name='Group 2622' transform='translate(10.972)'>
                            <g id='Group_2619' data-name='Group 2619' transform='translate(0)'>
                                <path id='Union_16' data-name='Union 16' d='M37.007,23.474a4.273,4.273,0,1,1,4.272,4.272A4.274,4.274,0,0,1,37.007,23.474Zm-28.187,0a4.272,4.272,0,1,1,4.272,4.272A4.273,4.273,0,0,1,8.82,23.474Zm26.842.959-16.954,0a5.7,5.7,0,1,0-11.314-.956,5.652,5.652,0,0,0,.081.956H1.291c-.015,0-.046,0-.09,0-.276,0-1.178-.055-1.2-.769a.786.786,0,0,1,.219-.595,1.35,1.35,0,0,1,.761-.34V15.546a4.622,4.622,0,0,1,3.891-4.285L8.757,2.793A5.228,5.228,0,0,1,13.576,0H29.933a5.475,5.475,0,0,1,3.545,1.2c.072.062.269.187.568.376l.013.008a26.649,26.649,0,0,1,5.154,4.043,28.424,28.424,0,0,1,3.916,5.481c.385.06,9.4,1.488,11.76,4.441a6.081,6.081,0,0,1,.895,5.032,11.393,11.393,0,0,1-.933,2.74c0,.595-1.245,1.091-1.3,1.111H46.9a5.7,5.7,0,1,0-11.314-.956,5.633,5.633,0,0,0,.081.956v0Zm15.905-9.107a4.2,4.2,0,0,0-.463,2.929,3.343,3.343,0,0,0,2.854,2.6.878.878,0,0,0,.937-.772,4.563,4.563,0,0,0-.995-3.935c-.669-.743-1.246-1.12-1.714-1.12A.8.8,0,0,0,51.568,15.326Zm-26.9-4.221H38.633A14.29,14.29,0,0,0,33.3,5.869c-2.62-1.3-6.4-1.439-7.91-1.439-.361,0-.606.009-.687.012h-.026Zm-11.1-5.236c-2.325,1.659-3.106,5.2-3.113,5.236H21.767V4.443c-.006,0-.288-.013-.778-.013C19.365,4.43,15.392,4.571,13.576,5.869Z' fill='#0b1f29' />
                            </g>
                        </g>
                    </g>
                </g>
                <path id='Subtraction_8' data-name='Subtraction 8' d='M56,132h0a204.914,204.914,0,0,1-28-22.427A122.373,122.373,0,0,1,8.75,86.466C2.944,77.092,0,68.122,0,59.808A68.2,68.2,0,0,1,4.4,35.085a55.53,55.53,0,0,1,12-18.849C26.607,5.766,40.669,0,56,0S85.391,5.766,95.6,16.236a55.533,55.533,0,0,1,12,18.849A68.189,68.189,0,0,1,112,59.808c0,8.314-2.944,17.284-8.75,26.657A122.409,122.409,0,0,1,84,109.573,204.915,204.915,0,0,1,56,132ZM56,15.611c-22.462,0-40.736,18.461-40.736,41.154S33.536,97.916,56,97.916,96.735,79.455,96.735,56.764,78.461,15.611,56,15.611Z' transform='translate(0 -0.024)' fill='#ffac4d' />
            </g>
        </g>
    </svg>
}

export default Logo