import React from 'react'
import BaseLayout from '../components/BaseLayout'
import Container from '../components/Container'
import Modal from '../components/Modal'
import TeamList from '../components/TeamList'
import TeamMemberDetails from '../components/TeamMemberDetails'
import TeamMembers from '../components/TeamMembers'
import { Team } from '../redux/actions/team.actions'
import { TeamMember } from '../redux/actions/teamMembers.actions'

export interface TeamContextObj {
    activeTeam: Team | null
    setActiveTeam: React.Dispatch<React.SetStateAction<Team | null>>
    activeTeamMember: TeamMember | null
    setActiveTeamMember: React.Dispatch<React.SetStateAction<TeamMember | null>>
}

export const TeamContext = React.createContext<TeamContextObj | null>(null)

const Teams = () => {
    const [activeTeam, setActiveTeam] = React.useState<Team | null>(null)
    const [activeTeamMember, setActiveTeamMember] = React.useState<TeamMember | null>(null)

    return <TeamContext.Provider value={{ activeTeam, setActiveTeam, activeTeamMember, setActiveTeamMember }}>
        <Modal visible={activeTeamMember !== null}>
            <TeamMemberDetails />
        </Modal>
        <BaseLayout>
            <div className='row'>
                <div className='col-6'>
                    <Container>
                        <div className='overflow-auto' style={{ height: 'calc(100vh - 200px)' }}>
                            <TeamList />
                        </div>
                    </Container>
                </div>
                <div className='col-6'>
                    <Container>
                        <div className='overflow-auto' style={{ height: 'calc(100vh - 200px)' }}>
                            <TeamMembers />
                        </div>
                    </Container>
                </div>
            </div>
        </BaseLayout>
    </TeamContext.Provider>
}

export default Teams