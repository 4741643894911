import 'chart.js/auto'
import { Chart as ChartJs } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { gothic, prussianBlue2 } from '../config'

interface Props {
    type: 'line' | 'bar'
    dataSetLabel: string
    labels: string[]
    data: number[]
    backgroundColor: string
}

const Chart = (props: Props) => {
    const { t } = useTranslation()

    return <ChartJs
        type={props.type}
        data={{
            labels: props.labels,
            datasets: [
                {
                    label: t(props.dataSetLabel),
                    data: props.data,
                }
            ],
        }}
        options={{
            responsive: true,
            borderColor: prussianBlue2,
            backgroundColor: props.backgroundColor,
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        color: gothic,
                        font: {
                            size: 12
                        }
                    }
                },
                y: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        color: gothic,
                        font: {
                            size: 12
                        }
                    },
                    beginAtZero: true
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        }}
    />
}

export default Chart
