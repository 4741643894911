import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import AuthendicationPage from '../components/AuthendicationPage'
import Button from '../components/Button'
import FormInput from '../components/FormInput'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { api, errorHandler } from '../config'
import { CreateNewPasswordNavigationState } from './CreateNewPassword'

const ForgotPassword = () => {
    const toast = useToast()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const continueClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (email === '') {
            setEmailError(t('Email required'))
            error = true
        }

        if (!error) {
            setLoading(true)
            api.post('user/sendOTP', { email: email }).then(response => {
                if (response.status === 200) {
                    toast(response.data.message ? t(response.data.message) : t('OTP sent'), ToastTypes.SUCCESS)
                    navigate('/create_new_password', {
                        state: {
                            email: email
                        } as CreateNewPasswordNavigationState
                    })
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }
            }).catch(error => {
                const text = errorHandler(error, {
                    errorText: 'Unable to sent OTP',
                    showConnectionError: true
                })

                toast(t(text), ToastTypes.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    return <AuthendicationPage headerText={t('Account Recovery')}>
        <form>
            <FormInput
                label={t('Email')}
                type='email'
                placeholder={t('Email')}
                containerClass='mb-24px'
                errorText={emailError}
                onChange={emailChangeHandler}
                value={email}
            />

            <Button
                containerClass='mb-44px'
                onClick={continueClickHandler}
                loading={loading}
            >{t('Continue')}</Button>

        </form>

        <div className='fs-14px fw-regular text-prussian-blue-2 text-center mb-8px'>{t('Remember password')}?</div>

        <Link to='/login' className='mt-button mb-44px'>{t('Login')}</Link>

        <div className='fs-14px fw-regular text-prussian-blue-2 text-center mb-8px'>{t('Don\'t have an account')}?</div>

        <Link to='/sign_up' className='mt-button'>{t('Sign up')}</Link>
    </AuthendicationPage>
}

export default ForgotPassword