import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import AuthendicationPage from '../components/AuthendicationPage'
import Button from '../components/Button'
import FormInput from '../components/FormInput'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { signup } from '../redux/actions/authUser.actions'
import { AppDispatch } from '../redux/store'
import { ActivateAccountNavigationState } from './ActivateAccount'

const Signup = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [confirmPassword, setConfirmPassword] = React.useState<string>('')

    const [emailError, setEmailError] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')
    const [confirmPasswordError, setConfirmPasswordError] = React.useState<string>('')

    const [loginLoading, setLoginLoading] = React.useState<boolean>(false)

    const resetErrorStates = () => {
        setEmailError('')
        setPasswordError('')
        setConfirmPasswordError('')
    }

    const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
        setConfirmPasswordError('')
    }

    const confirmPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfirmPassword(e.target.value)
        setConfirmPasswordError('')
    }

    const continueClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        resetErrorStates()

        let error = false

        if (email === '') {
            setEmailError(t('Email required'))
            error = true
        }

        if (password === '') {
            setPasswordError(t('Password required'))
            error = true
        }

        if (confirmPassword === '') {
            setConfirmPasswordError(t('Re-enter password'))
            error = true
        }

        if (password !== '' && confirmPassword !== '') {
            if (password !== confirmPassword) {
                setConfirmPasswordError(t('Passwords not match'))
                error = true
            }
        } else {
            error = true
        }

        if (!error) {
            const signupData = {
                email,
                password
            }

            setLoginLoading(true)
            dispatch(signup(signupData)).then(text => {
                toast(t(text))
                navigate('/activate_account', {
                    state: {
                        email: email,
                        password: password,
                        isLogin: false
                    } as ActivateAccountNavigationState
                })
            }).catch(error => {
                toast(t(error), ToastTypes.ERROR)
            }).finally(() => {
                setLoginLoading(false)
            })
        }
    }

    return <AuthendicationPage headerText={t('Sign up')}>
        <form>
            <FormInput
                label={t('Email')}
                type='email'
                placeholder={t('Email')}
                containerClass='mb-24px'
                errorText={emailError}
                onChange={emailChangeHandler}
                value={email}
            />

            <FormInput
                label={t('Password')}
                type='password'
                placeholder={t('Password')}
                containerClass='mb-24px'
                errorText={passwordError}
                onChange={passwordChangeHandler}
                value={password}
            />

            <FormInput
                label={t('Re-enter password')}
                type='password'
                placeholder={t('Re-enter password')}
                containerClass='mb-24px'
                errorText={confirmPasswordError}
                onChange={confirmPasswordChangeHandler}
                value={confirmPassword}
            />

            <Button
                containerClass='mb-44px'
                onClick={continueClickHandler}
                loading={loginLoading}
            >{t('Continue')}</Button>

        </form>

        <div className='fs-14px fw-regular text-prussian-blue-2 text-center mb-8px'>{t('Already have account')}?</div>

        <Link to='/login' className='mt-button'>{t('Login')}</Link>
    </AuthendicationPage>
}

export default Signup