import { Dispatch } from 'redux'
import { api, errorHandler, Response } from '../../config'
import { GetState } from '../reducers'

export enum TeamActionsList {
    ADD_TEAM = 'ADD_TEAM',
    UPDATE_TEAM = 'UPDATE_TEAM',
    DELETE_TEAM = 'DELETE_TEAM',
    FETCH_TEAMS = 'FETCH_TEAMS',
}

export type TeamStatus = 'active' | 'inactive'

export interface Team {
    id: string
    ownerId: string
    name: string
    company: string
    description: string
    caption: string
    status: TeamStatus
    cratedAt: string
    updatedAt: string
}

export interface AddTeamAction {
    type: TeamActionsList.ADD_TEAM
    data: Team
}

export interface UpdateTeamAction {
    type: TeamActionsList.UPDATE_TEAM
    data: Team
}

export interface FetchTeamsAction {
    type: TeamActionsList.FETCH_TEAMS
    data: Team[]
}

export interface DeleteTeamAction {
    type: TeamActionsList.DELETE_TEAM
    data: Team
}

export type TeamActions = AddTeamAction | UpdateTeamAction | DeleteTeamAction | FetchTeamsAction

export interface CreateTeam {
    name: string
    company?: string
    description?: string
    caption?: string
}

export const createTeam = (data: CreateTeam) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.post<Response<Team>>('team', data, config).then(response => {
        if (response.status === 200) {
            dispatch<AddTeamAction>({
                type: TeamActionsList.ADD_TEAM,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Team created')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to create team',
            showConnectionError: true
        }))
    })
}

interface UpdateTeam {
    name: string
    company?: string
    description?: string
    caption?: string
}

export const updateTeam = (data: UpdateTeam, teamId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.put<Response<Team>>(`team/${teamId}`, data, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateTeamAction>({
                type: TeamActionsList.UPDATE_TEAM,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Team updated')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to update',
            showConnectionError: true
        }))
    })
}

export const deleteTeam = (teamId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.delete<Response<Team>>(`team/${teamId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<DeleteTeamAction>({
                type: TeamActionsList.DELETE_TEAM,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Team deleted')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to delete',
            showConnectionError: true
        }))
    })
}

export const fetchTeams = () => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.get<Response<Team[]>>('team', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchTeamsAction>({
                type: TeamActionsList.FETCH_TEAMS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : '')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            logout: true,
            showConnectionError: true
        }))
    })
}
