import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useDimension from '../hooks/useDimension'
import { AuthUser } from '../redux/actions/authUser.actions'
import { StoreState } from '../redux/reducers'
import AccountSettings from './AccountSettings'
import Button from './Button'
import ChangeLanguage from './ChangeLanguage'
import Container from './Container'
import Modal from './Modal'
import SettingsModal from './SettingsModal'
import Globe from './svg/Globe'
import LogoSlogan from './svg/LogoSlogan'
import Menu from './svg/Menu'
import PlusCircle from './svg/PlusCircle'
import Settings from './svg/Settings'
import TripForm from './TripForm'

interface Props {
    showAddNewTrip?: boolean
    showTeams?: boolean
}

const Header = (props: Props) => {
    const { width } = useDimension()

    const [showHeader, setShowHeader] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (width >= 576) {
            setShowHeader(false)
        }
    }, [width])

    return <React.Fragment>
        <div className='mt-home-container py-26px'>
            <div className='d-flex align-items-center justify-content-between'>
                <Link to='/' className='d-block'>
                    <LogoSlogan />
                </Link>
                {width >= 576 && <div className='d-none d-sm-block'>
                    <HeaderRight {...props} />
                </div>}
                <div className='d-sm-none p-2 cursor-pointer' onClick={() => setShowHeader(!showHeader)}>
                    <Menu />
                </div>
            </div>
        </div>
        {showHeader && <div className='d-sm-none mt-home-container'>
            <Container className='d-flex align-items-center justify-content-center'>
                <HeaderRight />
            </Container>
        </div>}
    </React.Fragment>
}

interface HeaderRightProps {
    showAddNewTrip?: boolean
    showTeams?: boolean
}

const HeaderRight = (props: HeaderRightProps) => {
    const changeLanguageElement = React.useRef<HTMLDivElement>(null)
    const settingsModalElement = React.useRef<HTMLDivElement>(null)
    const changeLanguageIcon = React.useRef<SVGSVGElement>(null)
    const settingsModalIcon = React.useRef<SVGSVGElement>(null)

    const [showTripModal, setShowTripModal] = React.useState<boolean>(false)
    const [showChangeLanguage, setShowChangeLanguage] = React.useState<boolean>(false)
    const [showSettingsModal, setShowSettingsModal] = React.useState<boolean>(false)
    const [showAccountSettingsModal, setShowAccountSettingsModal] = React.useState<boolean>(false)

    const authUser = useSelector<StoreState, AuthUser>(state => state.authUser!)
    const { t } = useTranslation()

    const addNewTripClickHandler = () => {
        setShowTripModal(true)
    }

    const accountSettingsClickHandler: React.MouseEventHandler<HTMLAnchorElement> = () => {
        setShowAccountSettingsModal(true)
        setShowSettingsModal(false)
    }

    React.useEffect(() => {
        const documentClickHandler = (e: MouseEvent) => {
            if (changeLanguageIcon.current && changeLanguageIcon.current.contains(e.target as Node)) {
                setShowChangeLanguage(!showChangeLanguage)
            }

            if (settingsModalIcon.current && settingsModalIcon.current.contains(e.target as Node)) {
                setShowSettingsModal(!showSettingsModal)
            }

            if (settingsModalElement.current && !settingsModalElement.current.contains(e.target as Node)) {
                setShowSettingsModal(false)
            }

            if (changeLanguageElement.current && !changeLanguageElement.current.contains(e.target as Node)) {
                setShowChangeLanguage(false)
            }
        }

        document.addEventListener('mousedown', documentClickHandler)
        return () => {
            document.removeEventListener('mousedown', documentClickHandler)
        }
    }, [showChangeLanguage, showSettingsModal])

    return <React.Fragment>
        <Modal visible={showTripModal}>
            <TripForm
                formType='ADD'
                onCancel={() => setShowTripModal(false)}
            />
        </Modal>
        <AccountSettings
            show={showAccountSettingsModal}
            onCancel={() => setShowAccountSettingsModal(false)}
            onClose={() => setShowAccountSettingsModal(false)}
        />
        <div className='px-22px hstack gap-4'>
            {props.showAddNewTrip && <React.Fragment>
                <div className='d-none d-md-block'>
                    <Button variant='bordered' onClick={addNewTripClickHandler}>{t('Add new trip')}</Button>
                </div>
                <div className='d-md-none'>
                    <PlusCircle className='cursor-pointer' onClick={addNewTripClickHandler} />
                </div>
            </React.Fragment>}
            {props.showTeams && <React.Fragment>
                <div className='d-none d-md-block'>
                    <Link to='/teams' className='mt-button mt-button-bordered'>{t('Teams')}</Link>
                </div>
                <div className='d-md-none'>
                    <Link to='/teams' className='d-block'>
                        <PlusCircle />
                    </Link>
                </div>
            </React.Fragment>}
            <div className='position-relative cursor-pointer'>
                <Globe innerRef={changeLanguageIcon} />
                {showChangeLanguage && <ChangeLanguage innerRef={changeLanguageElement} />}
            </div>
            <div className='position-relative cursor-pointer'>
                <Settings innerRef={settingsModalIcon} />
                {showSettingsModal && <SettingsModal
                    innerRef={settingsModalElement}
                    onClickAccountSettings={accountSettingsClickHandler}
                />}
            </div>
            <div className='hstack gap-2'>
                <div className='text-678E9F fs-14px fw-regular'>{t('Hello')},<br />{authUser.name !== '' ? authUser.name : 'There'}</div>
                <div className='br-16px bg-0031490A w-58px h-58px d-flex align-items-center justify-content-center'></div>
            </div>
        </div>
    </React.Fragment>
}

export default Header