import { AxiosRequestConfig } from 'axios'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api, exportToExcel, Response } from '../config'
import { TeamContext, TeamContextObj } from '../pages/Teams'
import { AuthUser } from '../redux/actions/authUser.actions'
import { changeTeamMemberRole, changeTeamMemberStatus, deleteTeamMember, TeamMember, TeamMemberRole, TeamMemberStatus } from '../redux/actions/teamMembers.actions'
import { Trip } from '../redux/actions/trip.actions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import ConfirmationModal from './ConfirmationModal'
import Spinner from './Spinner'
import X from './svg/X'
import { ToastTypes, useToast } from './toast/ToastProvider'
import TripCard from './TripCard'

const TeamMemberDetails = () => {

    const { activeTeamMember, activeTeam, setActiveTeamMember } = React.useContext<TeamContextObj | null>(TeamContext)!

    if (!activeTeamMember) {
        return <React.Fragment />
    }

    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUser>(state => state.authUser!)
    const teamMembers = useSelector<StoreState, TeamMember[]>(state => state.teamMembers)
    const config = useSelector<StoreState, AxiosRequestConfig>(state => state.axiosRequestConfig!)
    const isOwner = activeTeam !== null && activeTeam.ownerId === authUser.userId
    const isManager = (() => {
        const index = teamMembers.findIndex(tm => tm.user.id === authUser.userId)
        return activeTeam !== null && index > -1 && teamMembers[index].role === 'manager'
    })()

    const [trips, setTrips] = React.useState<Trip[]>([])
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false)
    const [tripsLoading, setTripsLoading] = React.useState<boolean>(true)
    const [deleteTeamMemberLoading, setDeleteTeamMemberLoading] = React.useState<boolean>(false)

    const closeClickHandler = () => {
        setActiveTeamMember(null)
    }

    const roleChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        if (isOwner) {
            const role = e.target.value as TeamMemberRole
            setActiveTeamMember(prev => {
                return {
                    ...prev,
                    role: role
                } as TeamMember
            })
            dispatch(changeTeamMemberRole({
                role: role,
                teamId: activeTeamMember.teamId
            }, activeTeamMember.id)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        } else {
            toast('You are not the owner of this team')
        }
    }

    const statusChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        if (isOwner) {
            const status = e.target.value as TeamMemberStatus
            setActiveTeamMember(prev => {
                return {
                    ...prev,
                    status: status
                } as TeamMember
            })
            dispatch(changeTeamMemberStatus({
                status: status,
                teamId: activeTeamMember.teamId
            }, activeTeamMember.id)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        } else {
            toast('You are not the owner of this team')
        }
    }

    const removeTeamMemberClickHandler = () => {
        setShowDeleteModal(true)
    }

    const deleteYesClickHandler = () => {
        if (isOwner) {
            setDeleteTeamMemberLoading(true)
            dispatch(deleteTeamMember(activeTeamMember.teamId, activeTeamMember.id)).then(text => {
                toast(text)
                setShowDeleteModal(false)
                setActiveTeamMember(null)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            }).finally(() => {
                setDeleteTeamMemberLoading(false)
            })
        } else {
            toast('You are not the owner of this team')
        }
    }

    const exportClickHandler = () => {
        if (trips.length > 0) {
            exportToExcel(trips)
        } else {
            toast('No trips found', ToastTypes.ERROR)
        }
    }

    React.useEffect(() => {
        if (isOwner || isManager) {
            setTripsLoading(true)
            api.get<Response<Trip[]>>(`teamMember/trips/${activeTeamMember.teamId}/${activeTeamMember.id}`, config).then(response => {
                if (response.status === 200) {
                    setTrips(response.data.data)
                } else {
                    throw { response }
                }
            }).catch(error => {
                setTrips([])
            }).finally(() => {
                setTripsLoading(false)
            })
        }
    }, [])

    return <React.Fragment>
        <ConfirmationModal
            headerText='Are you sure want to remove'
            visible={showDeleteModal}
            onNo={() => setShowDeleteModal(false)}
            onYes={deleteYesClickHandler}
            loading={deleteTeamMemberLoading}
        />
        <div className='mt-team-member-container'>
            <div className='mb-16px text-center position-relative'>
                <span className='fs-20px fw-bold text-uppercase text-003651'>Team Member</span>
                <div className='position-absolute top-0 end-0 cursor-pointer' onClick={closeClickHandler}>
                    <X />
                </div>
            </div>
            <div className='d-flex align-items-start justify-content-between'>
                <div className='w-100'>
                    <div className='fs-16px text-dark-blue fw-medium'>{activeTeamMember.user.name}</div>
                    <div className='fs-16px text-dark-blue fw-medium'>{activeTeamMember.user.email}</div>
                    <div className='fs-16px text-dark-blue fw-medium'>{activeTeamMember.role}</div>
                    <div className='fs-16px text-dark-blue fw-medium'>{activeTeamMember.status}</div>
                </div>
                <div className='w-100'>
                    {(isOwner && activeTeamMember.role !== 'owner') && <React.Fragment>
                        <div className='fs-16px text-dark-blue fw-medium'>
                            <span className='me-2'>Assign member as:</span>
                            <select value={activeTeamMember.role} onChange={roleChangeHandler} className='br-3px border text-capitalize'>
                                {(['owner', 'manager', 'member'] as TeamMemberRole[]).map(role => {
                                    return <option value={role} key={role} disabled={role === 'owner'}>{role}</option>
                                })}
                            </select>
                        </div>
                        {(activeTeamMember.status === 'active' || activeTeamMember.status === 'inactive') && <div className='fs-16px text-dark-blue fw-medium'>
                            <span className='me-2'>Status:</span>
                            <select value={activeTeamMember.status} onChange={statusChangeHandler} className='br-3px border text-capitalize'>
                                {(['invited', 'active', 'inactive'] as TeamMemberStatus[]).map(status => {
                                    return <option value={status} key={status} disabled={status === 'invited'}>{status}</option>
                                })}
                            </select>
                        </div>}
                        <div className='fs-16px text-tall-poppy fw-medium cursor-pointer' onClick={removeTeamMemberClickHandler}>Remove member</div>
                    </React.Fragment>}
                </div>
            </div>
        </div>
        {(isOwner || isManager) && <React.Fragment>
            <div className='fs-20px fw-bold text-uppercase text-003651 my-16px text-center'>Trips</div>
            {trips.length > 0 && <div className='ms-22px fs-16px text-tall-poppy fw-medium cursor-pointer' onClick={exportClickHandler}>Export</div>}
            <div className='mt-team-member-scroll-container'>
                {tripsLoading
                    ? <div className='d-flex align-items-center justify-content-center'>
                        <Spinner />
                    </div>
                    : trips.length > 0
                        ? <div className='row'>
                            {trips.map(trip => {
                                return <TripComponent {...trip} key={trip.id} />
                            })}
                        </div>
                        : <div className='fs-12px fw-medium text-uppercase text-003651 my-16px text-center'>No trips found</div>}
            </div>
        </React.Fragment>}
    </React.Fragment>
}

type TripComponentProps = Trip

const TripComponent = (props: TripComponentProps) => {
    return <div className='col-md-6 col-lg-4 col-xxl-3 my-8px'>
        <TripCard {...props} />
    </div>
}

export default TeamMemberDetails