import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage, Language } from '../redux/actions/language.actions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import Radio from './Radio'

interface Props {
    innerRef?: React.LegacyRef<HTMLDivElement>
}

const ChangeLanguage = (props: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const language = useSelector<StoreState, Language>(state => state.language)

    const setLanguage = (lng: Language) => {
        dispatch(changeLanguage(lng))
    }

    return <div ref={props.innerRef}>
        <div className='mt-triangle' />
        <div className='mt-change-language'>
            <div className='fs-14px text-003651 fw-bold mb-5px'>{t('Change language')}</div>
            <div className='vstack gap-1'>
                <div className='hstack gap-2'>
                    <Radio name='language' id='finnish' onChange={() => setLanguage('fi')} checked={language === 'fi'}>{t('Finnish')}</Radio>
                </div>
                <div className='hstack gap-2'>
                    <Radio name='language' id='english' onChange={() => setLanguage('en')} checked={language === 'en'}>{t('English')}</Radio>
                </div>
            </div>
        </div>
    </div>
}

export default ChangeLanguage