import React from 'react'

type Props = React.SVGProps<SVGSVGElement>

const Excel = (props: Props) => {
    return <svg {...props} id='Microsoft_Office_Excel__2019_present_' data-name='Microsoft_Office_Excel_(2019–present)' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='25.812' height='24.011' viewBox='0 0 25.812 24.011'>
        <defs>
            <linearGradient id='linear-gradient' x1='0.174' y1='1.067' x2='0.826' y2='-0.063' gradientUnits='objectBoundingBox'>
                <stop offset='0' stopColor='#18884f' />
                <stop offset='0.5' stopColor='#117e43' />
                <stop offset='1' stopColor='#0b6631' />
            </linearGradient>
        </defs>
        <path id='Path_980' data-name='Path 980' d='M542.7,853.8,532.5,852v13.306a1.1,1.1,0,0,0,1.1,1.1h17.609a1.1,1.1,0,0,0,1.1-1.1h0v-4.9Z' transform='translate(-526.497 -842.396)' fill='#185c37' />
        <path id='Path_981' data-name='Path 981' d='M542.7,0h-9.1a1.1,1.1,0,0,0-1.1,1.1h0V6l10.2,6,5.4,1.8,4.2-1.8V6Z' transform='translate(-526.497 0)' fill='#21a366' />
        <path id='Path_982' data-name='Path 982' d='M532.5,532.5h10.2v6H532.5Z' transform='translate(-526.497 -526.497)' fill='#107c41' />
        <path id='Path_983' data-name='Path 983' d='M539.8,426h-7.3v15.007h7.3a1.1,1.1,0,0,0,1.1-1.1V427.1A1.1,1.1,0,0,0,539.8,426Z' transform='translate(-526.497 -421.198)' opacity='0.1' />
        <path id='Path_984' data-name='Path 984' d='M539.2,479.25h-6.7v15.007h6.7a1.1,1.1,0,0,0,1.1-1.1V480.35A1.1,1.1,0,0,0,539.2,479.25Z' transform='translate(-526.497 -473.848)' opacity='0.2' />
        <path id='Path_985' data-name='Path 985' d='M539.2,479.25h-6.7v13.806h6.7a1.1,1.1,0,0,0,1.1-1.1V480.35A1.1,1.1,0,0,0,539.2,479.25Z' transform='translate(-526.497 -473.848)' opacity='0.2' />
        <path id='Path_986' data-name='Path 986' d='M538.6,479.25h-6.1v13.806h6.1a1.1,1.1,0,0,0,1.1-1.1V480.35A1.1,1.1,0,0,0,538.6,479.25Z' transform='translate(-526.497 -473.848)' opacity='0.2' />
        <path id='Path_987' data-name='Path 987' d='M1.1,479.25H12.106a1.1,1.1,0,0,1,1.1,1.1v11.006a1.1,1.1,0,0,1-1.1,1.1H1.1a1.1,1.1,0,0,1-1.1-1.1V480.35A1.1,1.1,0,0,1,1.1,479.25Z' transform='translate(0 -473.848)' fill='url(#linear-gradient)' />
        <path id='Path_988' data-name='Path 988' d='M302.3,754.836l2.315-3.587-2.121-3.567H304.2l1.157,2.281q.16.325.22.484h.015q.114-.259.24-.5l1.237-2.26h1.566l-2.175,3.546,2.23,3.606h-1.666l-1.337-2.5a2.1,2.1,0,0,1-.16-.334h-.02a1.585,1.585,0,0,1-.155.324l-1.376,2.514H302.3Z' transform='translate(-298.892 -739.254)' fill='#fff' />
        <path id='Path_989' data-name='Path 989' d='M1446.254,0h-8.5V6h9.6V1.1a1.1,1.1,0,0,0-1.1-1.1Z' transform='translate(-1421.542 0)' fill='#33c481' />
        <path id='Path_990' data-name='Path 990' d='M1437.75,1065h9.6v6h-9.6Z' transform='translate(-1421.542 -1052.994)' fill='#107c41' />
    </svg>
}

export default Excel