import React from 'react'

type Props = React.SVGProps<SVGSVGElement>

const Briox = (props: Props) => {
    return <svg {...props} xmlns='http://www.w3.org/2000/svg' width='23.463' height='24' viewBox='0 0 23.463 24'>
        <g id='Group_2497' data-name='Group 2497' transform='translate(39 -115)'>
            <g id='briox-logo-brown' transform='translate(-39 115)'>
                <path id='Path_974' data-name='Path 974' d='M131.665,100.918l-3.85-2.886,3.85-2.886a.3.3,0,0,0,0-.491L120.252,86.1a.323.323,0,0,0-.386,0l-11.412,8.557a.307.307,0,0,0-.124.245v6.269a.307.307,0,0,0,.124.245l11.412,8.557a.323.323,0,0,0,.386,0l11.414-8.557a.3.3,0,0,0,0-.5ZM127.3,97.641l-6.919-5.188V86.97L130.956,94.9ZM120.063,93l6.715,5.035-6.715,5.035-6.715-5.035Zm-.316-6.025v5.483l-6.924,5.188L109.166,94.9Zm-10.778,8.557,3.339,2.5-3.339,2.5Zm11.09,13.8-10.893-8.162,3.657-2.742,7.043,5.28a.323.323,0,0,0,.386,0l7.047-5.285,3.653,2.747Z' transform='translate(-108.33 -86.035)' fill='#ff5000' />
            </g>
        </g>
    </svg>
}

export default Briox