import { Dispatch } from 'redux'
import { api, errorHandler, Response } from '../../config'
import { GetState } from '../reducers'

export enum TeamMembersActionsList {
    INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER',
    CHANGE_TEAM_MEMBER_ROLE = 'CHANGE_TEAM_MEMBER_ROLE',
    CHANGE_TEAM_MEMBER_STATUS = 'CHANGE_TEAM_MEMBER_STATUS',
    FETCH_TEAM_MEMBERS = 'FETCH_TEAM_MEMBERS',
    WIPE_TEAM_MEMBERS = 'WIPE_TEAM_MEMBERS',
    DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER'
}

export type TeamMemberStatus = 'invited' | 'invitation_expired' | 'active' | 'inactive'
export type TeamMemberRole = 'owner' | 'manager' | 'member'

export interface TeamMemberUser {
    id: string
    name: string
    email: string
}

export interface TeamMemberAddedUser {
    id: string
    name: string
    email: string
}

export interface TeamMember {
    id: string
    teamId: string
    user: TeamMemberUser
    addedBy: TeamMemberUser
    role: TeamMemberRole
    status: TeamMemberStatus
    createdAt: string
    updatedAt: string
}

interface InviteTeamMemberAction {
    type: TeamMembersActionsList.INVITE_TEAM_MEMBER
    data: TeamMember
}

interface ChangeTeamMemberRoleAction {
    type: TeamMembersActionsList.CHANGE_TEAM_MEMBER_ROLE
    data: TeamMember
}

interface ChangeTeamMemberStatusAction {
    type: TeamMembersActionsList.CHANGE_TEAM_MEMBER_STATUS
    data: TeamMember
}

interface FetchTeamMembersAction {
    type: TeamMembersActionsList.FETCH_TEAM_MEMBERS
    data: TeamMember[]
}

interface WipeTeamMembersAction {
    type: TeamMembersActionsList.WIPE_TEAM_MEMBERS
    data: null
}

interface DeleteTeamMemberAction {
    type: TeamMembersActionsList.DELETE_TEAM_MEMBER
    data: TeamMember
}

export type TeamMembersActions = InviteTeamMemberAction | FetchTeamMembersAction | WipeTeamMembersAction | DeleteTeamMemberAction | ChangeTeamMemberRoleAction | ChangeTeamMemberStatusAction

export interface CreateTeamMember {
    email: string
    teamId: string
}

export const inviteTeamMember = (data: CreateTeamMember) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.post<Response<TeamMember>>('teamMember/invite', data, config).then(response => {
        if (response.status === 200) {
            dispatch<InviteTeamMemberAction>({
                type: TeamMembersActionsList.INVITE_TEAM_MEMBER,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Team member invited')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to invite team member',
            showConnectionError: true
        }))
    })
}

interface ChangeTeamMemberRole {
    role: TeamMemberRole
    teamId: string
}

export const changeTeamMemberRole = (data: ChangeTeamMemberRole, teamMemberId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.put<Response<TeamMember>>(`teamMember/role/${teamMemberId}`, data, config).then(response => {
        if (response.status === 200) {
            dispatch<ChangeTeamMemberRoleAction>({
                type: TeamMembersActionsList.CHANGE_TEAM_MEMBER_ROLE,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Team member role changed')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to change team member role',
            showConnectionError: true
        }))
    })
}

interface ChangeTeamMemberStatus {
    status: TeamMemberStatus
    teamId: string
}

export const changeTeamMemberStatus = (data: ChangeTeamMemberStatus, teamMemberId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.put<Response<TeamMember>>(`teamMember/status/${teamMemberId}`, data, config).then(response => {
        if (response.status === 200) {
            dispatch<ChangeTeamMemberStatusAction>({
                type: TeamMembersActionsList.CHANGE_TEAM_MEMBER_STATUS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Team member status changed')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to change team member status',
            showConnectionError: true
        }))
    })
}

export const fetchTeamMembers = (teamId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.get<Response<TeamMember[]>>(`teamMembers/${teamId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchTeamMembersAction>({
                type: TeamMembersActionsList.FETCH_TEAM_MEMBERS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : '')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            showConnectionError: true
        }))
    })
}

export const wipeTeamMembers = () => async (dispatch: Dispatch) => {
    dispatch<WipeTeamMembersAction>({
        type: TeamMembersActionsList.WIPE_TEAM_MEMBERS,
        data: null
    })
}

export const deleteTeamMember = (teamId: string, teamMemberId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const config = getState().axiosRequestConfig!

    return api.delete<Response<TeamMember>>(`teamMember/${teamId}/${teamMemberId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<DeleteTeamMemberAction>({
                type: TeamMembersActionsList.DELETE_TEAM_MEMBER,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message ? response.data.message : 'Deleted')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject<string>(errorHandler(error, {
            errorText: 'Unable to delete',
            showConnectionError: true
        }))
    })
}
