import React from 'react'
import { useTranslation } from 'react-i18next'
import Logo from './svg/Logo'
import Splash from './svg/Splash'

interface Props {
    children?: React.ReactNode
    headerText?: string
}

const AuthendicationPage = (props: Props) => {
    const { t } = useTranslation()

    return <div className='bg-gallery vw-100 vh-100'>
        <div className='hstack'>
            <div className='d-none d-md-flex w-50 bg-white vh-100 align-items-center br-e-48px'>
                <Splash />
            </div>
            <div className='w-100 w-md-50 d-flex justify-content-center vh-100 overflow-auto'>
                <div className='mt-50px px-18px px-sm-0px w-100 w-sm-60 w-md-80 w-lg-60 w-xl-50'>
                    <div className='text-center'>
                        <Logo />
                        <div className='fs-24px text-uppercase text-prussian-blue fw-bold mt-16px'>{t('Welcome')}!</div>
                        <div className='fs-20px text-uppercase text-prussian-blue fw-regular mb-28px mt-48px'>{props.headerText}</div>
                    </div>
                    {props.children}
                    <div className='h-100px' />
                </div>
            </div>
        </div>
    </div>
}

export default AuthendicationPage