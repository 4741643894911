import { AxiosRequestConfig } from 'axios'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { api, errorHandler, Response } from '../config'
import { AuthUser, logout, updateUser } from '../redux/actions/authUser.actions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import Button from './Button'
import ConfirmationModal from './ConfirmationModal'
import FormInput from './FormInput'
import Label from './Label'
import Modal from './Modal'
import { ToastTypes, useToast } from './toast/ToastProvider'

interface Props {
    show?: boolean
    onCancel?: React.MouseEventHandler<HTMLButtonElement>
    onClose?: () => void
}

const AccountSettings = (props: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const user = useSelector<StoreState, AuthUser>(state => state.authUser!)
    const config = useSelector<StoreState, AxiosRequestConfig>(state => state.axiosRequestConfig!)

    const [name, setName] = React.useState<string>('')
    const [country, setCountry] = React.useState<string>('')

    const [nameError, setNameError] = React.useState<string>('')
    const [countryError, setCountryError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)
    const [showDeactivateConfirmationModal, setShowDeactivateConfirmationModal] = React.useState<boolean>(false)
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = React.useState<boolean>(false)
    const [deactivateLoading, setDeactivateLoading] = React.useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false)
    const [mounted, setMounted] = React.useState<boolean>(false)

    const nameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const countryChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCountry(e.target.value)
        setCountryError('')
    }

    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        const data = {
            country,
            name
        }

        setLoading(true)
        dispatch(updateUser(data)).then(text => {
            toast(text)
            if (typeof props.onClose === 'function') {
                props.onClose()
            }
        }).catch(text => {
            toast(text, ToastTypes.ERROR)
        }).finally(() => {
            setLoading(false)
        })
    }

    const deactivateClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        setShowDeactivateConfirmationModal(true)
    }

    const deleteClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        setShowDeleteConfirmationModal(true)
    }

    const deleteYesClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        setDeleteLoading(true)
        api.delete<Response>(`user/${user.userId}`, config).then(response => {
            if (response.status === 200) {
                toast(response.data.message ? t(response.data.message) : t('Account deleted'))
                dispatch(logout())
            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }
        }).catch((error) => {
            const text = errorHandler(error, {
                errorText: 'Unable to delete',
                showConnectionError: true
            })

            toast(t(text), ToastTypes.ERROR)
        }).finally(() => {
            if (mounted) {
                setDeleteLoading(false)
                setShowDeleteConfirmationModal(false)
            }
        })
    }

    const deleteNoClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        setShowDeleteConfirmationModal(false)
    }

    const deactivateYesClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        setDeactivateLoading(true)
        api.put(`user/deactivate/${user.userId}`, config).then(response => {
            if (response.status === 200) {
                toast(response.data.message ? t(response.data.message) : t('Account deactivated'))
                dispatch(logout())
            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }
        }).catch((error) => {
            const text = errorHandler(error, {
                errorText: 'Unable to deactivate',
                showConnectionError: true
            })

            toast(t(text), ToastTypes.ERROR)
        }).finally(() => {
            if (mounted) {
                setDeactivateLoading(false)
                setShowDeactivateConfirmationModal(false)
            }
        })
    }

    const deactivateNoClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        setShowDeactivateConfirmationModal(false)
    }

    React.useEffect(() => {
        setName(user.name)
        setCountry(user.country)
    }, [user])

    React.useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [])

    return <Modal visible={props.show}>
        <ConfirmationModal
            headerText={`${t('Are you want to deactivate')}?`}
            loading={deactivateLoading}
            onNo={deactivateNoClickHandler}
            onYes={deactivateYesClickHandler}
            visible={showDeactivateConfirmationModal}
        />
        <ConfirmationModal
            headerText={`${t('Are you want to delete')}?`}
            loading={deleteLoading}
            onNo={deleteNoClickHandler}
            onYes={deleteYesClickHandler}
            visible={showDeleteConfirmationModal}
        />
        <div className='fs-20px fw-bold text-uppercase text-003651 mb-16px text-center'>Account Information</div>
        <div className='mt-account-settings-modal-scroll-container'>
            <form>
                <FormInput
                    type='text'
                    label={t('Name')}
                    placeholder={t('Enter name')}
                    containerClass='mb-20px'
                    value={name}
                    errorText={nameError}
                    onChange={nameChangeHandler}
                />

                <FormInput
                    type='text'
                    label={t('Country')}
                    placeholder={t('Enter country')}
                    containerClass='mb-20px'
                    value={country}
                    errorText={countryError}
                    onChange={countryChangeHandler}
                />

                <div className='hstack gap-3 mt-24px'>
                    <Button
                        variant='bordered'
                        containerClass='w-100'
                        onClick={props.onCancel}
                        type='button'
                    >{t('Cancel')}</Button>
                    <Button
                        type='submit'
                        containerClass='w-100'
                        onClick={saveClickHandler}
                        loading={loading}
                    >{t('Save')}</Button>
                </div>

                <hr />

                <div className='mb-20px'>
                    <div className='hstack gap-3'>
                        <div className='w-100'>
                            <Label className='fw-500 fs-16px'>Deactivate account</Label>
                            <Label>Account will be inactive. You can reactivate it by signing in.</Label>
                        </div>
                        <div className='w-100'>
                            <Button onClick={deactivateClickHandler}>Deactivate</Button>
                        </div>
                    </div>
                </div>

                <div className='hstack gap-3'>
                    <div className='w-100'>
                        <Label className='fw-500 fs-16px'>Delete account</Label>
                        <Label>Account will be permanently deleted and all the data will be lost.</Label>
                    </div>
                    <div className='w-100'>
                        <Button onClick={deleteClickHandler}>Delete</Button>
                    </div>
                </div>
            </form>
        </div>
    </Modal>
}

export default AccountSettings