import axios, { AxiosRequestConfig } from 'axios'
import { logout } from '../redux/actions/authUser.actions'
import { updateNetworkError } from '../redux/actions/networkError.actions'
import { store } from '../redux/store'
import { apiUrl } from './env'

export interface Response<D = undefined> {
    error?: any
    data: D
    message?: string
}

export const api = axios.create({ baseURL: apiUrl })

export const getAxiosRequestConfig = (token: string): AxiosRequestConfig => {
    return {
        headers: {
            'auth-token': token
        }
    }
}

export interface ErrorHandlerConfig {
    errorText?: string
    showConnectionError?: boolean
    logout?: boolean
}

export const errorHandler = (error: any, config?: ErrorHandlerConfig) => {
    const defaultText = 'Unable to process request'
    if (error.code && error.code === 'ERR_NETWORK') {
        if (config && config.logout) {
            store.dispatch(logout())
        }
        if (config && config.showConnectionError) {
            store.dispatch(updateNetworkError(true))
        }
        return String(error.message
            ? error.message
            : config ?
                config.errorText ?
                    config.errorText
                    : defaultText
                : defaultText)
    } else if (error.response) {
        return String(error.response.data
            ? error.response.data.message
            : config ?
                config.errorText ?
                    config.errorText
                    : defaultText
                : defaultText)
    } else {
        return String(error.message
            ? error.message
            : config ?
                config.errorText ?
                    config.errorText
                    : defaultText
                : defaultText)
    }
}