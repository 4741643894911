import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TeamContext, TeamContextObj } from '../pages/Teams'
import { AuthUser } from '../redux/actions/authUser.actions'
import { fetchTeamMembers, TeamMember, wipeTeamMembers } from '../redux/actions/teamMembers.actions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import InviteTeamMemberForm from './InviteTeamMemberForm'
import Modal from './Modal'
import PlusCircle from './svg/PlusCircle'
import TeamMemberCard from './TeamMemberCard'

const TeamMembers = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { activeTeam } = React.useContext<TeamContextObj | null>(TeamContext)!
    const teamMembers = useSelector<StoreState, TeamMember[]>(state => state.teamMembers)
    const authUser = useSelector<StoreState, AuthUser>(state => state.authUser!)

    const isOwner = activeTeam !== null && activeTeam.ownerId === authUser.userId
    const isManager = (() => {
        const index = teamMembers.findIndex(tm => tm.user.id === authUser.userId)
        return activeTeam !== null && index > -1 && teamMembers[index].role === 'manager'
    })()

    const [showInviteTeamMemberModal, setShowInviteTeamMemberModal] = React.useState<boolean>(false)

    const inviteTeamMember = () => {
        if (activeTeam) {
            setShowInviteTeamMemberModal(true)
        }
    }

    React.useEffect(() => {
        dispatch(wipeTeamMembers())
        if (activeTeam) {
            dispatch(fetchTeamMembers(activeTeam.id))
        }
    }, [activeTeam])

    return <React.Fragment>
        <Modal visible={showInviteTeamMemberModal}>
            <InviteTeamMemberForm
                onCancel={() => setShowInviteTeamMemberModal(false)}
            />
        </Modal>
        <div className='container-fluid px-3'>
            <div className='d-flex align-items-center justify-content-between mb-32px'>
                <div className='fs-20px fw-bold text-uppercase text-003651'>Team members {activeTeam ? `- ${activeTeam.name}` : ''}</div>
                {activeTeam && (isOwner || isManager) && <PlusCircle className='cursor-pointer' onClick={inviteTeamMember} />}
            </div>
            <div className='row'>
                {teamMembers.map(teamMember => {
                    return <TeamMemberComponent {...teamMember} key={teamMember.id} />
                })}
            </div>
        </div>
    </React.Fragment>
}

type TeamMemberComponentProps = TeamMember

const TeamMemberComponent = (props: TeamMemberComponentProps) => {
    return <div className='col-6'>
        <div className='my-1'>
            <TeamMemberCard {...props} />
        </div>
    </div>
}

export default TeamMembers 