import { Dispatch } from 'redux'

export enum LanguageActionsList {
    CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
}

export type Language = 'en' | 'fi'

export interface AddLanguageAction {
    type: LanguageActionsList.CHANGE_LANGUAGE
    data: Language
}

export type LanguageActions = AddLanguageAction

export const changeLanguage = (language: Language) => async (dispatch: Dispatch) => {
    dispatch<AddLanguageAction>({
        type: LanguageActionsList.CHANGE_LANGUAGE,
        data: language
    })
}